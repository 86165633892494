import { useParams } from 'react-router-dom';
import { timeStamp } from 'console';
import React, { useEffect, useState } from 'react';
import { useToshi } from 'hooks';


import Cards from './components/Cards';
import { getPackContents } from 'toshi/utils';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import ToshiJSON from 'components/json/toshimon.json'






const Deck: React.FC = () => {
  let cards:number[] = [];
  const [cardsWon, setCardsWon] = useState<number[]>([])
  //@ts-ignore
  let { id, quantity } = useParams();

  const getEvents = () => {
    getPackContents().events.allEvents().on('data', (event: any) => {
      if(Number(event.raw.topics[1]) == id){
        cards.push(Number(event.raw.topics[2]))
        if(cards.length == quantity)
          setCardsWon(cards);
       
      }else{
        console.log(Number(event.raw.topics[1]));
      }
    }).on("message", (event: any) => {
      console.log(event)
    })
    .on('error', console.error);
  
  }

  useEffect(() => {
    console.log("PopPop")
    getEvents()
  },[]);
  console.log(cardsWon);
  return (

    <section className="page-content clearfix">    
      <div id="flavours">
        <div id="toshimon" className="tabcontent">
        
            {cardsWon.length ? 
              <Cards cards={cardsWon} />
            :
            <div style={{textAlign:"center"}}>
              <div>Your pack(s) are opening on Polygon</div>
             <Loader
                type="Puff"
                color="#b5159e"
                height={350}
                width={350}
              />
              <div>DO NOT REFRESH</div>
              <div>Pack opening takes 5-10 minutes, but can take up to an hour</div>
              </div>
          }
        </div>
                  
        </div>   
      </section>
    );
};

export default Deck;
