import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useToshi } from 'hooks';

import Button from 'components/Button';

import ToshiJSON from 'components/json/items.json'

const InsetImage = styled.img`
  padding: 5px;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin: inheret auto;
  background-color: rgb(240, 233, 231);
  box-shadow: rgb(226, 214, 207) 4px 4px 8px inset,
    rgb(247, 244, 242) -6px -6px 12px inset;
`; 

const ArrowRight = styled.div`
position: absolute;
padding: 1rem; /* Arrow size */
box-shadow: 1px -1px 0 1px plum inset;
-webkit-box-shadow: 2px -2px plum inset;
border: solid transparent;
border-width: 0 0 2rem 2rem;
transition: 0.2s;
transform:rotate(225deg);
top:calc(50% - 1rem);
right:-1.75em;
display:none;


  
  &:hover {
    box-shadow: 2px -2px 0 2px orchid inset;
    -webkit-box-shadow: 4px -4px orchid inset;
    
  
  }
`;
const ArrowLeft = styled.div`
position: absolute;
padding: 1rem; /* Arrow size */
box-shadow: 1px -1px 0 1px plum inset;
-webkit-box-shadow: 2px -2px plum inset;
border: solid transparent;
border-width: 0 0 2rem 2rem;
transition: 0.2s;
transform:rotate(45deg);
top:calc(50% - 1rem);
left:-1.75em;
display:none;
  
  &:hover {
    box-shadow: 2px -2px 0 2px orchid inset;
    -webkit-box-shadow: 4px -4px orchid inset;
    }
  
  
`;
const Hover = styled.div`
  position:relative;
    
  &:hover ${ArrowLeft} {
    display: block;
  }
  &:hover ${ArrowRight} {
    display: block;
  }
`;


const Cards: React.FC = () => {

  const [cardExpanded, setCardExpanded] = useState<any>({undefined});
  const [cardID, setCardId] = useState<any>({undefined});
  const [demCount, setDemCount] = useState<any>({undefined});
  const [cardArray, setCardArray] = useState<Array<any>>([]);
  const [hover, setHover] = useState<any>("start");
  const {
  
  
    toshimonBalance,
    toshimonBalanceMatic

  } = useToshi();



  useEffect(() => {
  
    setCardExpanded(null);
    setHover(null)

    if (toshimonBalance) {
      let ret = [];
      for(let i = 0; i <= 311; i++){
        

       

          let cardBal = (Number(toshimonBalance[i]) || 0) + (Number(toshimonBalanceMatic[i]) || 0)
         
   
         
          const cardArr = ToshiJSON;
          let c:any = {}
          for(let ca in cardArr){
            if(cardArr[ca].Index == i+1 ){
              c = cardArr[ca];
              break;
            }
          }
          cardBal > 0 &&   c.iType == "Item" &&
              ret.push({
                  src:"https://api.toshimon.io/Items/"+c["Number"],
                  name:c["Toshimon Name"],
                  id:c["Number"],
                  owned:cardBal,
                  index:c["Index"]
              })
      }
      setCardArray(ret);
     
    }
  }, [
    toshimonBalance,
    toshimonBalanceMatic
    

  ]);
  const getCardID = (i:number) => {
    const cardArr = ToshiJSON;
    let c:any = {}
    for(let ca in cardArr){
      if(cardArr[ca].Number == i && cardArr[ca].iType == "Item"){
        c = cardArr[ca];
        break;
      }
    }
    return c["Index"];
  }
  

  const onCardSelected = (event: any) =>{
    let src = event.currentTarget?.getAttribute("src")
    if(src){

      let id = parseInt(src.substr(src.lastIndexOf("/")+1,src.lastIndexOf(".") - src.lastIndexOf("/") - 1)) - 1;
      setCardId(id+1);
      let card = cardArray.find((card: any) => card.id == id + 1)
      setDemCount(card.owned)
    }
   
    event.currentTarget.className == "dialog" ?
    
    setCardExpanded(null)
    :
    setCardExpanded(event.currentTarget.src);
  }


  
  return (
    <div >

      {[...Array(12)].map((e, i) =>  {
        let card = cardArray.find((card: any) => card.id == i + 1)
        return cardArray && card && card ? 
        
        <li className="item">
        <Hover className="">
         
            <img
              onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
              onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
              style={{cursor:"pointer",borderRadius: "5%/4%"}}
              src={card.src+".png"}
              onClick={onCardSelected}
              alt={card.name}
              
            
            />
            <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{card.name}</div>
            <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+card.owned}</div>
            
          </Hover>
          </li>
        
        :
        i != 150 &&
        <li className="item"
        onMouseEnter = {e => {
              
          setHover(i)
         }}
         onMouseLeave = {e => {
           setHover(null)
           
         }}
     >
            <img
          
          style={{borderRadius: "5%/4%",display: hover == "start" || hover == i ? "none" : "block"}}
          src={"/images/CardBack.jpg"}
          alt="Toshimon Card Back"
          
        
        />
        <img
          
          style={{borderRadius: "5%/4%",display: hover == "start" || hover == i ? "block" : "none"}}
              src={"https://api.toshimon.io/Items/"+(i+1)+".png"}
              alt="Toshimon Card Back"
              
            
            />
            <div>
            <div className= "font-semibold" style={{textAlign:"center",width:"100%",display: hover == i ? "none" : "block"}}>{i+1+"/12"}</div>
            <a target="_blank" style={{display:hover == i ? "block" : "none"}} href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/"+getCardID(i+1)}><button className="hmeMXj">Buy on Opensea</button></a>
            </div>
          </li>
        

      })}
     {cardExpanded && (
        <dialog
          className="dialog flex flex-col w-screen min-h-screen items-center"
          style={{ position: 'fixed', minHeight: "100vh", top: '0', minWidth:"100%", backgroundColor:"rgba(0,0,0,.7)" }}
          open
          onClick={onCardSelected}
        >
          <div onClick={onCardSelected}>
            <div style={{position:"fixed",top:15,right:150}}>
              <button className="hmeMXj">
              
                {"Owned: "+ demCount}
              </button>
            </div>
            <div style={{position:"fixed",top:15,right:280}}>
            <a href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/"+cardID}>
            <button className="hmeMXj">
                
               {"View on Opensea"}
               
              
              </button>
            </a>
            </div>
            <div style={{textAlign:"center"}}>
            <img
              style={{boxShadow: "0 0 20px #B63BBF",width:"auto",borderRadius: "5%/4%", maxWidth: "90vw" , maxHeight: "80vh", marginTop: "10vh", marginLeft:"auto", marginRight:"auto"}}
              src={cardExpanded}
              alt={cardExpanded}
            />
            </div>
            
            
          </div>
          
        </dialog>
      )}
 
      
    </div>
  );
};

export default Cards;
