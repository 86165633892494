import { useToshi } from 'hooks';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

const Store: React.FC = () => {



  const {
    toshiBalance,
    cobieBalances,
    fuse,
    approveFusion,
    allowanceFusion
  } = useToshi();




  const fuseCobie = async () => {
    return fuse(321, 1);
  };

  const checkbal = () => {
    return (Number(cobieBalances && cobieBalances[0] || '0') > 0 && Number(cobieBalances && cobieBalances[1] || '0') > 0 && Number(toshiBalance) >= 10)
  }
  return (
    <section className="page-content clearfix">
      <div id="flavours">
        <div className="tab buypack">
          <div id="contant-outer">
            <div id="buy" className="tabcontent tabcon active" >
              <h1>Toshimon Fusion</h1>
              <div className="col-sm-12">

                <div className="box" style={{paddingTop:"20px",marginBottom: "40px"}}>
                  <img style={{ padding: "20px", maxHeight: "70vh", width: "auto" }} src="images/Cobie.png" />
                  <p>Owned: {cobieBalances && cobieBalances[2] || "?"}</p>
                  <p>Cost: 10 Toshi • 1 Cobber • 1 Lianti</p>
                  {checkbal() && Number(allowanceFusion) > 0 ?
                   <button style={{ cursor: "pointer" }} onClick={fuseCobie}>Buy</button> 
                   : 
                   checkbal()
                   ? 
                   <button onClick={approveFusion}>Approve TOSHI Spend</button>
                  :
                  Number(toshiBalance) < 10 ? 
                  <button style={{ cursor: "default" }} disabled > You need 10 TOSHI</button> 
                  :
                  Number(cobieBalances && cobieBalances[0] || '0') < 1 ?
                  <button style={{ cursor: "default" }} disabled > You need 1 Cobber</button> 
                  :
                  <button style={{ cursor: "default" }} disabled > You need 1 Lianti</button>}
                 
                  <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/321" >Buy on OpenSea</ a></div>
                  


                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Store;
