import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/Button';
import Card from 'components/Card';

import Header from 'components/Header';
import { useToshi } from 'hooks';





const GreyPill = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 400px;
  padding: 10px 1rem;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  background-color: ${({ theme }) => theme.color.grey[400]};
`;

const BlueLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.color.blue[400]};

  &:hover {
    opacity: 0.8;
  }
`;

const BorderedHeader = styled.h3`
  border-bottom: 1px solid ${({ theme }) => theme.color.grey[400]};
`;

const Landing: React.FC = () => {
  const [toshiboyHeight, setToshiBoyHeight] = useState<number>(0);
  const {
    toshimonLPBalance
  } = useToshi();

  window.addEventListener('resize', e => setToshiBoyHeight(document.getElementById("ToshiboyImage")?.offsetHeight || 0));
  return (
    <section className="page-content clearfix">  
      <div id="play">
      {toshimonLPBalance?.beeStaked > 0 || toshimonLPBalance?.greyStaked > 0 ?
        <><iframe style={{ width: "1024px", height: "798px" }} src={'game/index.html'}></iframe>
            <div className="intro">
            </div></>
        :
        <>
          <div id="play">
          <br/>
          <iframe src="https://player.vimeo.com/video/533783966?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="800" height="600" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Toshimon Battle"></iframe>
          </div>
            <div className="intro">
              <div>You must own and stake atleast one Cyber Hornet or Greyscale to access the game beta</div>
              <br/>
              <a href="store">GET cyber Hornet or Greyscale</a>
            </div>
          </>

      }

      </div>
     </section>
  );
};

export default Landing;
