
export const contractAddresses = {
    toshi: {
        1: '0xF136D7b0B7AE5b86D21E7B78DFA95375a7360f19',
       
        4: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        80001:"0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1"

    },
    toshiEthLp: {
        1: '0x49179a590b086ee09dacc5750cfdb312c0c73d10',
        
        4: '0xfab46e002bbf0b4509813474841e0716e6730136',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        80001:"0xFab46E002BbF0b4509813474841E0716E6730136"
    },
    toshiCoin: {
        1: '0x3eeff4487f64bf73cd9d99e83d837b0ef1f58247',

        4: '0xa13737252F6B23EE7E806E2b8B525fed4EF99748',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        80001:"0xFab46E002BbF0b4509813474841E0716E6730136"
    },
    toshiCash: {
        1: '0xb6E0b9eDc711c89B9259E5ff04AF48255C500Ead',

       // 4: '0xb6E0b9eDc711c89B9259E5ff04AF48255C500Ead',
       4: '0xd4cfe53ad7967b81e132761b3e47b4d11f7cdc59',
       5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        80001:"0xFab46E002BbF0b4509813474841E0716E6730136"
    },
    
    toshiCoinFarm: {
        1: '0x50c2ac66ED6Da83A8A2eEEAfD468d9dEF522e03A',
       
        4: '0x1E0b39A512C57F5DdBae2377a8960D4fB9d60bE1',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        80001:"0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1"
    },    
    toshiCashFarm: {
        1: '0x1aA8fecdf71d25Fbadb4981143dCE6613B1ffc8D',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
       
        4: '0x94BE38aB543F5fECdBB36463F39Ce6cAD674dF7E',
        80001:"0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1"
    },
    toshiDojo: {
        1: '0x8cf409db94e93ea627368eb0a0c5f8760eacbbfa',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        4: '0x890bdB67A98e3B872514Bf8e935495F1B1Df2839',
        80001:"0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1"
    },
    toshimonMinter: {
        1: '0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB',
    
        4: '0x224A62B0F30DFb6f61Ff1ae3eFc23D8919f73173',
        5: '0x4df4947cd0d097d99e739290b017023e6f7815d5',
        137: '0x7658ab44641352046Bde4Aa6df9A62966F439893',
        80001:"0x8eA8AB3DC0160b445d6513a9006377e3d979f4Da"
    },
    toshimonMinterMatic: {
        1: '0x7658ab44641352046bde4aa6df9a62966f439893',
        
        4: '0x224A62B0F30DFb6f61Ff1ae3eFc23D8919f73173',
        5: '0x4df4947cd0d097d99e739290b017023e6f7815d5',
        137: '0x7658ab44641352046Bde4Aa6df9A62966F439893',
        80001:"0x8eA8AB3DC0160b445d6513a9006377e3d979f4Da"
    },
    toshimonVendor: {
        1: '0xa36D8FDE675BAc4fDd7c172Ea2dB9C3a827eE7C9',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        4: '0x928A35E4d3732Cf678855e771b55E4772159fEeF',
        80001:"0xBB407e6030895d88da8Da8Cb04a6550e99c73005"
    },
    toshimonVendorBee: {
        1: '0xA833645635409Fc7Bdd5d9C5305CA1c2e6233eA8',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
        4: '0x928A35E4d3732Cf678855e771b55E4772159fEeF',
        80001:"0xBB407e6030895d88da8Da8Cb04a6550e99c73005"
    },
    weth:{
        1: '0x49179a590b086ee09dacc5750cfdb312c0c73d10',
        4: '0xd4cfe53ad7967b81e132761b3e47b4d11f7cdc59',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
    },
    opensea:{
        1: '0x7be8076f4ea4a4ad08075c2508e481d6c946d12b',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
    },
    toshimonStakeContract:{
        1: '0x0f2cd12497781Ac6497fBd5F653ee49f6EF65860',
        4: '0xb4149574e5690Edc24d647dA3AF2a90bf78fcd95',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
    },
    toshimonVendorFree:{
        1: '0xeF531C7F740D42606dCe46292c25714FBdb7E41D',
        4: '0xD72c78a1601b4e98e20D6e16991C87Bf4Fc8a45f',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
    },
    toshimonFusion:{
        1: '0x09cDe5494BD1b3cFe0b9cB2D01e583A4D1D35De6',
        4: '0x15d722424cbBcF2E6B946FE9Bb1CF7d7A6bfCf95',
        5: '0xaFF4481D10270F50f203E0763e2597776068CBc5',
    },
    toshimonDojoRootTunnel:{
        1: '0xfc8d9a306ed8aef77a28e465fd8210f1dcb9ba68',
        5: '0xfe2d89f660DBde21210DeA2705de1a7D33F733bA'

    },
    maticDojo:{
        137: '0x57f7CcE6e5DD39001e21D44A394135A076288804',
        80001: '0x13351324fFBfd7940219F62155e47a80dF623E9F'
    },
    mooncatMinter:{
        1: '0xaf59c0a2Baa2305D40a868c3762c40eEBDfc03ef',
        4: '0x941F70D56b659a5a59DAc5f30E64a53C190218F0'
    }
    
}
