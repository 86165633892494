import { useToshi } from 'hooks';
import { floor } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";





const localStorageKey = 'AppHome__activeTab';

const Dojo: React.FC = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem(localStorageKey) || '1'
  );

  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
    localStorage.setItem(localStorageKey, tab);
  };
  const [redeemAmount, setRedeemAmount] = useState<any>(1);
  const [packOpening, setPackOpening] = useState<any>(undefined);
  const [packLoading, setPackLoading] = useState<any>(undefined);

  const {
    redeemPack,
    redeemPackMatic,
    toshiPackBalance,
    

  redeemCardPack,
  pricePack
  } = useToshi();


  useEffect(() => {
    if (toshiPackBalance) {
      setRedeemAmount(parseFloat(toshiPackBalance) < 25 ? toshiPackBalance : "25");
    }
  },[toshiPackBalance]);


  const onChangeRedeemAmount = (event: any) =>
    setRedeemAmount(String(event.target.value).replace(/[^\d.-]/g, ''));

  const redeem = async () => {
    setPackLoading(true);
    setTimeout(()=> {
      setPackLoading(false);
      setPackOpening(true)}
    ,15000)
    await redeemPack(
      Number(redeemAmount) > Number(toshiPackBalance) ? toshiPackBalance : redeemAmount
    );

    console.log("pls")
  };
  
  const redeemMatic = async () => {
    setPackLoading(true);

    const {id, quantity} = await redeemPackMatic(
      Number(redeemAmount) > Number(toshiPackBalance) ? toshiPackBalance : redeemAmount
    );
    console.log(quantity);
    setPackLoading(false);
    id && history.push('/pack/'+id+"/"+quantity);
  };
  const buyPack = async () => {
    return redeemCardPack();
  };



  return (
    <section className="page-content clearfix">    
    <div id="flavours">
      <div className="tab buypack">
      <button onClick= {e => changeActiveTab("0")} className={activeTab == "0" ? "tablink active": "tablink"} data-id="buy">Buy Packs</button>
      <button onClick= {e => changeActiveTab("1")} className={activeTab == "1" ? "tablink active": "tablink"} data-id="tokens">Open Packs</button>
      </div>
      
      <div id="contant-outer">
      <div id="buy" className={activeTab == "0" ? "tabcontent tabcon active" : "tabcontent tabcon"}>
      <h1>Buy Toshi Packs</h1>
        <div className="col-sm-12">
        <div className="d-flex justify-content-center bg-secondary mb-3">
        <div className="col-sm-4">
        <div className="box">
        <img src="image/cardpack1.png"/>
        <p><span>{toshiPackBalance ? toshiPackBalance : 0}</span>
        <span>Pack Balance</span></p>
        <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target="_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/0"  >Buy on OpenSea</ a></div>
        <div style={{fontSize:"14px"}}>*Buying multiple saves on gas fees</div>

        
        </div>
      </div>
      </div>
      </div>
      </div>
      
      <div id="tokens" className={activeTab == "1" ? "tabcontent openpack tabcon active" : "tabcontent openpack tabcon"}>
      <h1>Open Toshi Packs</h1>
        <div className="col-sm-12">
        <div className="d-flex justify-content-center bg-secondary mb-3">
        <div className="col-sm-4">
        <div className="box">
        <img src="image/cardpack1.png"/>
        <p><span>{parseFloat(toshiPackBalance) >= 1 ? parseFloat(toshiPackBalance).toFixed(0) : 0}</span>
        <span>Pack Balance</span></p>
        {parseFloat(toshiPackBalance) >= 1 ? 
          <div>
            <input value={redeemAmount} className="slider" onChange={onChangeRedeemAmount} type="range" min="1" max={parseFloat(toshiPackBalance) < 25 ? toshiPackBalance : "25"} />
            <div><button style={{cursor:"pointer"}}  onClick={redeemMatic}>Open {redeemAmount} Packs to Polygon</button></div>
            <div style={{fontSize:"16px", paddingBottom:"20px"}}><a style={{cursor:"pointer"}}  onClick={redeem}>Open {redeemAmount} Packs to Eth</a></div>
            
          </div>
          : 
          <>
            <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target="_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/0"  >Buy on OpenSea</ a></div>
            <div style={{fontSize:"14px"}}>*Buying multiple saves on gas fees</div>
          </>

        }
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    </div>
    {packLoading && 
      <div style={{overflow:"hidden" ,position: "fixed", top:0, left:0, right:0, height:"100vh", backgroundColor:"rgba(0,0,0,0.5)", zIndex:99999}}>
        <div style={{position: "fixed", top:"calc(50vh - 200px)", left:"calc(50vw - 200px)"}}>
          <Loader
            
            type="Puff"
            color="#b5159e"
            height={400}
            width={400}
          />
        </div>
      </div>
    }
  </section>
  );
};
/*<button style={{cursor:"pointer"}}  onClick={
  e=>{
    try{
      redeem()
    }
    catch(e){
        alert("You need more eth for the gas to complete this transaction")
    }
  }
    }>Open {redeemAmount} Packs</button>*/
export default Dojo;
