import { useContext } from 'react'

import { Context } from 'contexts/Toshi'

const useToshi = () => {
  const toshiContext = useContext(Context)
  return toshiContext
}

export default useToshi
