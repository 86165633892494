import React, { useCallback, useContext } from 'react';

import Button from '../../Button';
import {Context} from "../../../contexts/Toshi";

interface AccountButtonProps {
  balance?: string;
  className?: string;
}

const AccountButton: React.FC<AccountButtonProps> = ({
  balance,
  className,
}) => {
  const { account, connecty} = useContext(Context);

  const handleUnlockClick = useCallback(() => {
    connecty()  
  }, [connecty]);

  return (
    <div className={className}>
      {!account ? (
        <div
          onClick={handleUnlockClick}
         
  

        >Unlock Wallet
        </div>
      ) : (
        <div
          
         
          >
          {`${account.substr(0, 7)}...${account.substr(
            account.length - 5
          )}`}
          </div>
      )}
    </div>
  );
};

export default AccountButton;
