import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';


import './styles.css'

import { useToshi } from 'hooks';

import AccountButton from './components/AccountButton';
import { Link } from 'react-router-dom';


const Header = () => {

  const { toshiBalance, toshiCoinBalance, toshiPackBalance, toshiCashBalance } = useToshi();
  const [menuOpen, toggleMenuOpen] = useState<boolean>(false);


  return (
    <header className="page-header clearfix">
    <a className="logo" href="/">
      <img src="image/logo.png" alt="logo" />
    </a>
    <img className="headchara" src="image/characters.png" alt="characters" />
        <span className="menu-opener" onClick={e => toggleMenuOpen(!menuOpen)}>
      <i className="fa fa-bars" aria-hidden="true"></i>
    </span>
    <nav className = {menuOpen ? "opened animated bounceInRight" : ""}>
  <span className="menu-closer" onClick={e => toggleMenuOpen(!menuOpen)}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </span>
    <div className="unlockbutton">
    <button className="sc-dlnjPT hmeMXj w-full" color="#FFF" font-size="14" > <AccountButton balance={toshiBalance} /></button>    
    </div>
    <div className="unlockbottom">
    <div className="mr-5 ml-5"><div className="text-base pt-4 sm:pb-5 pr-3 text-right" style={{width: "30%", display: "inline-block"}}><b>{toshiPackBalance ? toshiPackBalance : 0 } </b></div>
    <div className="text-base pt-4 sm:pb-5 pr-3  text-right" style={{width: "70%", display: "inline-block"}}>
      <span className="font-thin">Packs (1st Gen)</span>
    </div>
    <div className="text-base pt-4 sm:pb-5 pr-3  text-right" style={{width: "30%", display: "inline-block"}}><b>{toshiBalance ?  parseFloat(toshiBalance).toFixed(2) : 0} </b></div>
    <div className="text-base pt-4 sm:pb-5 pr-3  text-right" style={{width: "70%", display: "inline-block"}}>
      <span className="font-thin">TOSHI</span>
    </div>
    <div className="text-base pt-4 sm:pb-5 pr-3  text-right" style={{width: "30%", display: "inline-block"}}><b>{toshiCashBalance ?  parseFloat(toshiCashBalance).toFixed(0) : 0} </b></div>
    <div className="text-base pt-4 sm:pb-5 pr-3  text-right" style={{width: "70%", display: "inline-block"}}>
      <span className="font-thin">ToshiCash</span>
    </div>
   
      </div>
    </div> 
    <span className="sep">&nbsp;</span>
    <ul id="menu-main" className="main-menu">
    <li><Link to="https://toshimon.io" > Home</Link></li>
    <li><Link to="/Deck" > My Cards </Link></li>
    <li><Link to="/BuyPacks" > Farm</Link></li>
    <li><Link to="/OpenPacks" > Buy/Open Packs</Link></li>
    <li><Link to="/store" > Store</Link></li>
    <li><Link to="/Play" > Play</Link></li>
    <li><Link to="/Fusion" > Fusion</Link></li>
  
  </ul>      
  <span className="sep">&nbsp;</span>
      <ul id="menu-secondary" className="secondary-menu">
<li><a href="https://app.uniswap.org/#/swap?inputCurrency=0xf136d7b0b7ae5b86d21e7b78dfa95375a7360f19" target="_blank">Get TOSHI</a></li>
<li><a href="https://app.uniswap.org/#/add/ETH/0xF136D7b0B7AE5b86D21E7B78DFA95375a7360f19"  target="_blank">Get TOSHI/ETH LP</a></li>
</ul>     
<ul className="social-menu">
                  <li><a href="https://twitter.com/Toshimon_" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                  <li><a href="https://t.me/TOSHIOFFICIAL" target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a></li>
          <li><a href="https://medium.com/@ToshimonOfficial" target="_blank"><i className="fa fa-medium" aria-hidden="true"></i></a></li>
          <li><a href="https://discord.gg/P2uXSx6xfw" target="_blank"><img src="image/discord2.png" /></a></li>
          
      </ul>
    </nav>
  </header>
    );
};

export default Header;
