import { useToshi } from 'hooks';
import React, { Suspense, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Canvas } from "react-three-fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

function Model() {
  const { scene } = useGLTF("/3d/MoonCat.glb")
  return <primitive object={scene} style={{width:"100%"}}/>;
}
function Model2() {
  const { scene } = useGLTF("/3d/CUDLnaut.glb")
  return <primitive object={scene} style={{width:"100%"}}/>;
}

const Store: React.FC = () => {



  const {
    redeemCard,
    cobie1Remaining,
    cobie2Remaining,
    redeemCardBee,
    toshiCashBalance,
    approveBee,
    toshimonLPBalance,
    pricePack,
    redeemCardPack,
    toshiPackBalance,
    redeemFromOpenSea,
    marenRemaining,
    redeemCardFree,
    polyBalance,
    tokenBalanceToshiCash,
     allowanceBee,
     nextCat,
     mintMooncat,
     mintMooncatToshicash,
     mintCUDl,
     toshimonBalance
  } = useToshi();




  const buyBee = async () => {
    return redeemCardBee(888);
  };
  const mintCat = async () => {
    return mintMooncat(nextCat);
  };
  const buyPoly = async () => {
    return redeemCardFree(9999);
  };
  const buyGrey = async () => {
    return redeemCardBee(696);
  };
  const buyPack = async () => {
    return redeemCardPack();
  };
  const buyCobie1 = async () => {
    return redeemCard(319);
  };
  const buyCobie2 = async () => {
    return redeemCard(320);
  };
  const buyMaren = async () => {
    return redeemFromOpenSea(317);
  };
  const approveBeeFxn = async () => approveBee();





  return (
    <section className="page-content clearfix">
      <div id="flavours">
        <div className="tab buypack">
          <div id="contant-outer">
            <div id="buy" className="tabcontent tabcon active" >
              <h1>ToshiCash Store</h1>
              <div className="col-sm-12" style={{marginBottom:'50px'}}>
                <div className="d-flex justify-content-center bg-secondary mb-3">

                  
              <div className="col-sm-4">
                      <div className="box">

                        <img style={{ padding: "20px" }} src="images/Polymon.png" />
                        <p><span>Free Starter</span>
                          <span>Toshimon!</span></p>
                          



                  {!polyBalance ? <button style={{ cursor: "pointer" }} onClick={buyPoly}>Redeem!</button> : <button style={{ cursor: "default" }}  disabled={true}>Already Redeemed!</button> }

                        <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/9999"  >Buy on OpenSea</a></div>



                      </div>

                    </div>
                    <div className="col-sm-4">
                    <div className="box">
                      <img style={{ padding: "20px" }} src="images/Cobber.png" />
                      <p><span>{(50 - parseInt(cobie1Remaining)) || 0} /50</span>
                        <span>Remaining</span></p>


                      {
                      toshiCashBalance ?
                        cobie1Remaining == "50" ?
                          
                          <button style={{cursor:"default"}} disabled >Sold Out</button> :
                          parseFloat(toshiCashBalance) >= 100000 ?
                            <button style={{cursor:"pointer"}} onClick={buyCobie1}>Buy for 100,0000 ToshiCash</button> :
                            <button style={{cursor:"default"}} disabled >You need 100,000 ToshiCash</button> :
                        <button style={{cursor:"default"}} disabled >loading...</button> 
     

                      }
                      <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/319"  >Buy on OpenSea</ a></div>



                    </div>

                  </div>
                  <div className="col-sm-4">
                    <div className="box">
                      <img style={{ padding: "20px" }} src="images/Lianti.png" />
                      <p><span>{(50 - parseInt(cobie2Remaining)) || 0} /50</span>
                        <span>Remaining</span></p>

                      {
                        toshiCashBalance ?
                        cobie2Remaining == "50" ?
                          
                          <button style={{cursor:"default"}} disabled >Sold out</button> :
                          parseFloat(toshiCashBalance) >= 100000 ?
                            <button style={{cursor:"pointer"}} onClick={buyCobie2}>Buy for 100,0000 ToshiCash</button> :
                            <button style={{cursor:"default"}} disabled >You need 100,000 ToshiCash</button> :
                        <button style={{cursor:"default"}} disabled >loading...</button> 
     

                      }
                      <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/320"  >Buy on OpenSea</ a></div>



                    </div>

                  </div>
                  </div>
                  </div>
                  <div className="col-sm-12">
                <div className="d-flex justify-content-center bg-secondary mb-3">

              <div className="col-sm-4">

                <div className="box" style={{paddingTop:"20px",marginBottom: "40px"}}>
                  <div style={{ textAlign: "center", height:"360px", width: "calc(100% - 40px)", overflow: "hidden", marginLeft: "20px", marginRight: "20px", marginBottom: "20px", borderRadius:"3%/4.5%" }}>
                    <video autoPlay preload="true" loop style={{
                      position: "relative",
                      left: "50%",
                      height:360,
                      transform: "translate(-50%,-50%)",
                      top: "50%",
                    }}>
                      <source src="videos/bee.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <p>
                    <span>Owned: {toshimonLPBalance ? toshimonLPBalance.bee: "?"}</span>
                        
                  </p>


                  {parseFloat(tokenBalanceToshiCash) >= 3 && Number(allowanceBee) > 0 ?
                   <button style={{ cursor: "pointer" }} onClick={buyBee}>Buy for 3 TOSHI/ETH LP</button> 
                   : 
                   parseFloat(tokenBalanceToshiCash) >= 3
                   ? 
                   <button onClick={() => approveBeeFxn()}>Approve TOSHI/ETH Lp</button>
                  : 
                  <button style={{ cursor: "default" }} disabled > You need 3 TOSHI/ETH LP</button>}
                 
                  <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/888" >Buy on OpenSea</ a></div>
                  <div style={{fontSize:"14px"}}>*this card generates 3x ToshiCash</div>
                  


                </div>

              </div>
              <div className="col-sm-4">

              <div className="box" style={{paddingTop:"20px",marginBottom: "40px"}}>
                <img style={{ height:"370px", width:"235px" }} src="/image/cardpack1.png" />
                  <p>
                    <span>Owned: {toshiPackBalance ? toshiPackBalance : "?"}</span>
                        
                  </p>
 
                  
                  <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target="_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/0"  >Buy on OpenSea</ a></div>
                  <div style={{fontSize:"14px"}}>*Buying multiple saves on gas fees</div>



                </div>

              </div>
              <div className="col-sm-4">
              <div className="box" style={{paddingTop:"20px",marginBottom: "40px"}}>
                  <div style={{ textAlign: "center", height:"360px", width: "calc(100% - 40px)", overflow: "hidden", marginLeft: "20px", marginRight: "20px", marginBottom: "20px", borderRadius:"3%/4.5%" }}>
                        <video autoPlay preload="true" loop style={{
                        position: "relative",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        top: "50%",

                        height:360
                      }}>
                        <source src="videos/grey.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                  </div>
                  <p>
                    <span>Owned: {toshimonLPBalance ? toshimonLPBalance.grey : "?"}</span>
                        
                  </p>
                  {parseFloat(tokenBalanceToshiCash) >= 1 && Number(allowanceBee) > 0 ?
                   <button style={{ cursor: "pointer" }} onClick={buyGrey}>Buy for 1 TOSHI/ETH LP</button> 
                   : 
                   parseFloat(tokenBalanceToshiCash) >= 1
                   ? 
                   <button onClick={() => approveBeeFxn()}>Approve TOSHI/ETH LP</button>
                  : 
                  <button style={{ cursor: "default" }} disabled > You need 1 TOSHI/ETH LP</button>}
                  <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target="_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/696"  >Buy on OpenSea</ a></div>
                  <div style={{fontSize:"14px"}}>*this card generates 1x ToshiCash</div>



                </div>

              </div>
</div></div>

                  <div className="col-sm-12">
                <div className="d-flex justify-content-center bg-secondary mb-3">

                  
              <div className="col-sm-4">

                <div className="box" style={{paddingTop:"20px",marginBottom: "40px"}}>
                  <div style={{ textAlign: "center", height:"400px", width: "calc(100% - 40px)", overflow: "hidden", marginLeft: "20px", marginRight: "20px", marginBottom: "20px", borderRadius:"3%/4.5%" }}>
                  <div style={{ textAlign: "center", marginTop: "-300px", height:"1000px", width: "100%", overflow: "hidden"}}>


                    <Canvas >
                      <ambientLight intensity={.8} />
                      <Suspense fallback={null}>
                        <Model />
                      </Suspense>
                      <OrbitControls />
                    </Canvas>
                  </div>
                  </div>
                  <p>
                    <span>Owned: {toshimonBalance ? toshimonBalance[322]: "?"}</span>
                        
                  </p>



                  <button disabled >Mint Ended!</button>
                  
                <div style={{fontSize:"20px"}}>free: 1 per Ponderware Mooncat owned</div>
                  <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/322" >Buy on OpenSea</ a></div>
                  
                </div>

              </div>
              <div className="col-sm-4">

                <div className="box" style={{paddingTop:"20px",marginBottom: "40px"}}>
                  <div style={{ textAlign: "center", height:"400px", width: "calc(100% - 40px)", overflow: "hidden", marginLeft: "20px", marginRight: "20px", marginBottom: "20px", borderRadius:"3%/4.5%" }}>
                  <div style={{ textAlign: "center", marginTop: "-300px", height:"1000px", width: "100%", overflow: "hidden"}}>


                    <Canvas >
                      <ambientLight intensity={.8} />
                      <Suspense fallback={null}>
                        <Model />
                      </Suspense>
                      <OrbitControls />
                    </Canvas>
                  </div>
                  </div>
                  <p>
                  <span>Owned: {toshimonBalance ? toshimonBalance[322]: "?"}</span>
                        
                  </p>



                  <button disabled >Mint Ended!</button>
                    
                  <div style={{fontSize:"20px"}}>100,000 Toshicash per mint</div>
                  <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/322" >Buy on OpenSea</ a></div>
                  
                </div>

                </div>
                <div className="col-sm-4">

                  <div className="box" style={{paddingTop:"20px",marginBottom: "40px"}}>
                    <div style={{ textAlign: "center", height:"400px", width: "calc(100% - 40px)", overflow: "hidden", marginLeft: "20px", marginRight: "20px", marginBottom: "20px", borderRadius:"3%/4.5%" }}>
                    <div style={{ textAlign: "center", marginTop: "-300px", height:"1000px", width: "100%", overflow: "hidden"}}>


                      <Canvas >
                        <ambientLight intensity={.8} />
                        <Suspense fallback={null}>
                          <Model2 />
                        </Suspense>
                        <OrbitControls />
                      </Canvas>
                    </div>
                    </div>
                    <p>
                    <span>Owned: {toshimonBalance ? toshimonBalance[323]: "?"}</span>
                          
                    </p>



                    <button style={{ cursor: "pointer" }} onClick={mintCUDl}>Mint CUDLNAUT</button> 

                      
                    <div style={{fontSize:"20px"}}>0.1 ETH per mint</div>
                    <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/323" >Buy on OpenSea</ a></div>
                    
                  </div>

              </div>
              </div></div>

            

              <div className="col-sm-12">
                <div className="d-flex justify-content-center bg-secondary mb-3">

                  
                  <div className="col-sm-4">
                      <div className="box">

                        <img style={{ padding: "20px" }} src="images/Maren.png" />
                        <p><span>{(marenRemaining) || 0} /50</span>
                          <span>Remaining</span></p>



                  {marenRemaining == 0 ? <button style={{ cursor: "default" }} disabled >SOLD OUT</button> : <button style={{ cursor: "pointer" }} onClick={buyMaren}>Buy for 1 Eth</button> }

                        <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href = "https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/317"  >Buy on OpenSea</a></div>



                      </div>

                    </div>
                  <div className="col-sm-4">
                    <div className="box">
                      <img style={{ padding: "20px" }} src="images/Neram.png" />
                      <p><span>0 /100</span>
                        <span>Remaining</span></p>


                      <button style={{ cursor: "default" }} disabled >SOLD OUT</button>
                      <div style={{ cursor: "pointer", marginBottom: "20px" }}>< a target = "_blank" href="https://opensea.io/assets/0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb/318"  >Buy on OpenSea</ a></div>



                    </div>

                  </div>                
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Store;
