import React from 'react';


const Footer = () => {
  return (
    <footer className="page-footer clearfix" style={{marginTop:"20px"}}>
              <div className="newsletter-form">
                <h3>Join the Community</h3>
                <div className="clearfix">
                
                </div>
                <div className="output"></div>
              </div>
              <div className="align-right">
                <h3>Toshimon</h3>
                <ul className="social-menu">                  
                            <li><a href="https://twitter.com/Toshimon_" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                            <li><a href="https://t.me/TOSHIOFFICIAL" target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a></li>
                    <li><a href="https://medium.com/@ToshimonOfficial" target="_blank"><i className="fa fa-medium" aria-hidden="true"></i></a></li>
                    <li><a href="https://discord.gg/P2uXSx6xfw" target="_blank"><img className="foot-discord" src="image/discord.png"/></a></li>
                </ul>
              </div>
              <nav>
                <ul id="menu-secondary-1" className="secondary-menu clearfix">

              <li className="copy">© 2020 <a href="">Toshimon</a>. All rights reserved.</li></ul>    </nav>
            </footer>
  );
};

export default Footer;
