import React, { useEffect, useState } from 'react';
import axios from 'axios'
import styled from 'styled-components';

import { useToshi } from 'hooks';

import Modal from 'react-modal';
import Card from './../Card';

import ToshiJSON from 'components/json/toshimon.json'
import { Link } from 'react-router-dom';

Modal.setAppElement('#root')

function getCardAttr (card: any, attribute: String) {
  return card.attributes.find((attr: { trait_type: String; }) => attr.trait_type === attribute).value
}

const InsetImage = styled.img`
  padding: 5px;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin: inheret auto;
  background-color: rgb(240, 233, 231);
  box-shadow: rgb(226, 214, 207) 4px 4px 8px inset,
    rgb(247, 244, 242) -6px -6px 12px inset;
`; 

const ArrowRight = styled.div`
position: absolute;
padding: 1rem; /* Arrow size */
box-shadow: 1px -1px 0 1px plum inset;
-webkit-box-shadow: 2px -2px plum inset;
border: solid transparent;
border-width: 0 0 2rem 2rem;
transition: 0.2s;
transform:rotate(225deg);
top:calc(50% - 1rem);
right:-1.75em;
display:none;


  
  &:hover {
    box-shadow: 2px -2px 0 2px orchid inset;
    -webkit-box-shadow: 4px -4px orchid inset;
    
  
  }
`;
const ArrowLeft = styled.div`
position: absolute;
padding: 1rem; /* Arrow size */
box-shadow: 1px -1px 0 1px plum inset;
-webkit-box-shadow: 2px -2px plum inset;
border: solid transparent;
border-width: 0 0 2rem 2rem;
transition: 0.2s;
transform:rotate(45deg);
top:calc(50% - 1rem);
left:-1.75em;
display:none;
  
  &:hover {
    box-shadow: 2px -2px 0 2px orchid inset;
    -webkit-box-shadow: 4px -4px orchid inset;
    }
  
  
`;
const Hover = styled.div`
  position:relative;
    
  &:hover ${ArrowLeft} {
    display: block;
  }
  &:hover ${ArrowRight} {
    display: block;
  }
`;

const CardModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgb(204, 204, 204);
`

const CardModalTitle = styled.h3`
  margin: 0;
`

const CardModalImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 1rem;
`

const CardModalDesc = styled.p`
  color: #606060;
`

// const CardModalStatTable = styled.dt`t 
//   margin-top: 1rem;
//   margin-bottom: 2rem;
//   font-size: 0.75em;
// `



const Cards: React.FC = () => {
  const {
    toshimonBalance,
    toshimonBalanceMatic,
  } = useToshi();
  console.log(toshimonBalance)
  const [cards, setCards] = useState<any>([]);
  useEffect(() => {
    const cardData:any[] = [];
    toshimonBalanceMatic?.map((e: any, i: any) =>  {
      const card = ToshiJSON.find(card => card.Index == i + 1)
      const id = card?.Number || 0;
      const index = card?.Index || 0;
      const balance = (Number(toshimonBalance[index]) || 0) + (Number(toshimonBalanceMatic[index]) || 0)
      total = (total + Number(toshimonBalanceMatic[index]));
      if(total > 0 && id != 0 ){
          cardData.push({
              id: id,
              name: card ? card["Toshimon Name"] : "",
              owned: balance.toString(),
              index: index
          })
        }
    })
    let sorted = cardData.sort((a:any, b:any)=>{
      return a.id < b.id ? -1 : 1;
    })
    console.log(sorted)
    setCards(sorted)
  },[toshimonBalance,toshimonBalanceMatic])
  let total = 0;
  return cards.length ? (
    < >
      {cards?.map((card: any, i: any) =>  {
       
        console.log(total);
        return cards.length && card.owned !== "0" &&
        <li className="item">
            <Card
              id={card?.id}
              name={card?.name}
              owned={card?.owned}
              index={card?.index}
            />
          </li>
          
      })}

    </>
  )
  :
  <div style={{width:"100%", textAlign:"center", marginTop:"100px", marginBottom:"100px"}}>You have no cards! Head over to <Link to="/OpenPacks">Buy/Open Packs</Link></div>
};

export default Cards;