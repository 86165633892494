import { timeStamp } from 'console';
import React, { useEffect, useState } from 'react';
import { useToshi } from 'hooks';

import Cosmetics from './components/Cosmetics';
import Items from './components/Items';
import Cards from './components/Cards';
import TMMs from './components/TMMs';
import Demeanors from './components/Demeanors';
import Special from './components/Special';




const Deck: React.FC = () => {

  const [pageName, openPage] = useState<string>("toshimon");


  return (

    <section className="page-content clearfix">    
    <div id="flavours">
    <div className="tab">
                      <button className="tablink" onClick={e => openPage('toshimon')} id="defaultOpen">toshimon</button>
                      <button className="tablink" onClick={e => openPage('special')}>Special Edition</button>
                      <button className="tablink" onClick={e => openPage('TMMS')}>TMMS</button>
                      <button className="tablink" onClick={e => openPage('Cosmetics')}>Cosmetics</button>
                      <button className="tablink" onClick={e => openPage('Items')}>Items</button>
                      <button className="tablink" onClick={e => openPage('Demeanors')}>Demeanors</button>
                      
                    </div>
    
                    {pageName == "toshimon" && 
                      <div id="toshimon" className="tabcontent">
                        <ul className="clearfix">
                        <Cards/>
                        </ul>
                         </div>
                    }
                    {pageName == "special" && 
                      <div id="special" className="tabcontent">
                        <ul className="clearfix">
                        <Special/>
                        </ul>
                         </div>
                    }
                    {pageName == "TMMS" && 
                    <div id="TMMS" className="tabcontent">
                      <ul className="clearfix">
                               <TMMs/>
                               </ul>
                    </div>
                    }
                    {pageName == "Demeanors" && 
                    <div id="Demeanors" className="tabcontent">
                      <ul className="clearfix">
                               <Demeanors/>   
                                  </ul>
                    </div>
                    }
                    {pageName == "Cosmetics" && 
                    <div id="Cosmetics" className="tabcontent">
                      <ul className="clearfix">
                                  <Cosmetics/>
                                  </ul>
                    </div>
                    }
                    {pageName == "Items" && 
                    <div id="Items" className="tabcontent">
                      <ul className="clearfix">
                              <Items />
                      </ul>
                    </div>
                    }  
        </div>   
      </section>
    );
};

export default Deck;
