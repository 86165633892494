import React, { useState, useEffect } from 'react';
import { get } from 'lodash';

import { useToshi } from 'hooks';



const AppHome: React.FC = () => {
  const [stakeAmountGrey, setstakeAmountGrey] = useState<any>(0);
  const [stakeAmountBee, setstakeAmountBee] = useState<any>(0);
  const [unstakeAmountGrey, setunstakeAmountGrey] = useState<any>(0);
  const [unstakeAmountBee, setunstakeAmountBee] = useState<any>(0);
  const POOL_ID = 0;
  const POOL_ID_TOSHICASH = 1;

  const {

    toshimonLPBalance,
    depositToshimonStakePool,
    withdrawToshimonStakePool,
    harvestToshimon,
    claimableToshiCash
    


  } = useToshi();


  const onChangeAmountGrey = (event: any) =>
    setstakeAmountGrey(String(event.target.value).replace(/[^\d.-]/g, ''));
  const onChangeAmountBee = (event: any) =>
    setstakeAmountBee(String(event.target.value).replace(/[^\d.-]/g, ''));
  const onChangeAmountGreyUnstake = (event: any) =>
    setunstakeAmountGrey(String(event.target.value).replace(/[^\d.-]/g, ''));
  const onChangeAmountBeeUnstake  = (event: any) =>
    setunstakeAmountBee(String(event.target.value).replace(/[^\d.-]/g, ''));

  const harvest = async () => {
    return harvestToshimon();
  };
  const stake = async (id: string) => {
    if(id == "888"){
      return depositToshimonStakePool(
        id,
        Number(stakeAmountBee ) > Number(toshimonLPBalance.bee) ? toshimonLPBalance.bee : stakeAmountBee
      )
    }
    else{
      return depositToshimonStakePool(
        id,
        Number(stakeAmountGrey) > Number(toshimonLPBalance.grey) ? toshimonLPBalance.grey : stakeAmountGrey
      );
    }
  };

  const unstake = async (id: string) => {
    if(id == "888"){
      return withdrawToshimonStakePool(
        id,
        Number(unstakeAmountBee ) > Number(toshimonLPBalance.beeStaked) ? toshimonLPBalance.beeStaked : unstakeAmountBee
      )
    }
    else{
      return withdrawToshimonStakePool(
        id,
        Number(unstakeAmountGrey) > Number(toshimonLPBalance.greyStaked ) ? toshimonLPBalance.greyStaked : unstakeAmountGrey
      );
    }
  };

  const harvestToshiCash = async () => {
    return withdrawToshimonStakePool(
      "888",
      "0"
    );
  };




  return (
    <section className="page-content clearfix">
      <div id="flavours">
        <div className="tab buypack">

        </div>

        <div id="contant-outer">

          <div id="tokens" className="tabcontent tabcon active">
           <h1>Stake your NFTs to passively earn ToshiCash</h1>
            <div className="col-sm-12">
              <div className="d-flex justify-content-center bg-secondary mb-3">

                <div className="col-sm-4">
                  <div className="box" style={{ overflow: "hidden" }}>
                    <div style={{ textAlign: "center", height: "360px", width: "100%", overflow: "hidden", marginBottom: "20px" }}>
                      <video autoPlay preload="true" loop style={{
                        position: "relative",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        top: "50%",

                        height: 360
                      }}>
                        <source src="videos/bee.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <span>Owned: {toshimonLPBalance && toshimonLPBalance.bee || "?"}</span>

                    
                    <input value={stakeAmountBee} className="slider" name="amount" onChange={onChangeAmountBee} type="range" min="0" max={toshimonLPBalance && toshimonLPBalance.bee  ? toshimonLPBalance.bee : "0"} />

                    <button disabled={stakeAmountBee < 1 ? true: false} onClick={() => stake("888")}>Stake {stakeAmountBee} Cyber Hornet</button>
                    <div></div>
                    

                    { toshimonLPBalance && Number(toshimonLPBalance.beeStaked) > 0 && (
                      <div>
                      <span>Staked: {toshimonLPBalance && toshimonLPBalance.beeStaked || "?"}</span>
                      <input value={unstakeAmountBee} className="slider" name="amount" onChange={onChangeAmountBeeUnstake} type="range" min="0" max={toshimonLPBalance && toshimonLPBalance.beeStaked ? toshimonLPBalance.beeStaked : "0"} />
                   
                      <button disabled={unstakeAmountBee < 1 ? true: false}  onClick={() => unstake("888")}>Unstake {unstakeAmountBee} Cyber Hornet</button>
                      </div>

                    )
                    }

                    

                    <p><small>Add {Number(stakeAmountBee * 3000 || 0).toLocaleString('en-US', { maximumFractionDigits: 0 })} ToshiCash per Day</small></p>


                  </div>
                </div>
              
                <div className="col-sm-4">
                  <div className="box">
                    <img src="image/ToshiCoinIcon.png" />

                    <p><span>{Number(claimableToshiCash || 0).toLocaleString('en-US', {
                      maximumFractionDigits: 6,
                    })}</span>
                      <span>ToshiCash Earned</span></p>
                    <button onClick={harvestToshiCash}>Harvest</button>
                    <p><small>{toshimonLPBalance ? (Number(toshimonLPBalance.beeStaked) * 3000) + ( Number(toshimonLPBalance.greyStaked) * 1000) : 0} ToshiCash per Day</small></p>

                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="box" style={{ overflow: "hidden" }}>
                    <div style={{ textAlign: "center", height: "360px", width: "100%", overflow: "hidden", marginBottom: "20px" }}>
                      <video autoPlay preload="true" loop style={{
                        position: "relative",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        top: "50%",

                        height: 360
                      }}>
                        <source src="videos/grey.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <span>Owned: {toshimonLPBalance && toshimonLPBalance.grey || "?"}</span>
                    <div></div>
                    
                    
                    <input value={stakeAmountGrey} className="slider" name="amount" onChange={onChangeAmountGrey} type="range" min="0" max={toshimonLPBalance && toshimonLPBalance.grey ? toshimonLPBalance.grey : "0"} />

                    

                    <button disabled={stakeAmountGrey < 1 ? true: false} onClick={() => stake("696")}>Stake {stakeAmountGrey} Greyscale</button>

                    <div></div>

                    

                    { toshimonLPBalance && Number(toshimonLPBalance.greyStaked) > 0 && (
                      <div>
                        <span>Staked: {toshimonLPBalance && toshimonLPBalance.greyStaked || "?"}</span>
                       <input value={unstakeAmountGrey} className="slider" name="amount" onChange={onChangeAmountGreyUnstake} type="range" min="0" max={toshimonLPBalance && toshimonLPBalance.greyStaked ? toshimonLPBalance.greyStaked : "0"} />
                  
                      <button disabled={unstakeAmountGrey < 1 ? true: false}  onClick={() => unstake("696")}>Unstake {unstakeAmountGrey} Greyscale</button>
                      </div>

                    )
                    }



                    <p><small>Add {Number(stakeAmountGrey * 1000 || 0).toLocaleString('en-US', { maximumFractionDigits: 0 })} ToshiCash per Day</small></p>


                  </div>
                </div>
                  
                  
                    
                

              
            </div>
           
          </div>
        </div>
        </div>
      </div>
    </section>
  ); 
};

export default AppHome;
