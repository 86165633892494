
import BigNumber from 'bignumber.js'


import { Contracts } from './lib/contracts.js'


import { contractAddresses } from './lib/constants'

export class Toshi {
    constructor() {

        const networkId = 1 ;

        this.contracts = new Contracts()
        
        this.toshiAddress = contractAddresses.toshi[networkId]
        this.toshiEthLpAddress = contractAddresses.toshiEthLp[networkId]
        this.toshiCoinAddress = contractAddresses.toshiCoin[networkId]
        this.toshiCashAddress = contractAddresses.toshiCash[networkId]
        this.toshimonFusionAddress = contractAddresses.toshimonFusion[networkId]
        this.toshiCashFarmAddress = contractAddresses.toshiCashFarm[networkId]
        this.toshiDojoAddress = contractAddresses.toshiDojo[networkId]
        this.toshimonMinterAddress = contractAddresses.toshimonMinter[networkId]
        this.toshimonDojoRootTunnelAddress = contractAddresses.toshimonDojoRootTunnel[networkId]
        this.mooncatMinterAddress = contractAddresses.mooncatMinter[networkId]
    }




    toBigN(a) {
        return BigNumber(a)
    }
}
