import React, { useEffect, useState } from 'react';
import axios from 'axios'
import styled from 'styled-components';

import { useToshi } from 'hooks';

import Modal from 'react-modal';
import Card from './../Card';

import ToshiJSON from 'components/json/toshimon.json'
import ItemJSON from 'components/json/items.json'
import { displayPartsToString } from 'typescript';

Modal.setAppElement('#root')

function getCardAttr (card: any, attribute: String) {
  return card.attributes.find((attr: { trait_type: String; }) => attr.trait_type === attribute).value
}


// const CardModalStatTable = styled.dt`t 
//   margin-top: 1rem;
//   margin-bottom: 2rem;
//   font-size: 0.75em;
// `

interface CardsProps {
  cards:number[];
}



const Cards: React.FC<CardsProps> = ({cards}) => {
  const toMatrix = (arr: any[], width: number) => 
    arr.reduce((rows: any[][], key: any, index: number) => (index % width == 0 ? rows.push([key]) 
      : rows[rows.length-1].push(key)) && rows, []);
  return (
    < >
      {toMatrix(cards, 8)?.map((e: any, i: any) =>  {
        let pack = [];
        for(let c in e){
          let card:any = ToshiJSON.find(card => card.Index == e[c])
          if(!card?.Number ){
            card = ItemJSON.find(card => card.Index == e[c])
          }
          const id = card?.Number || 0;
          const index = card?.Index || 0;
          const name = card ? card["Toshimon Name"] ? card["Toshimon Name"] : card["Name"] : " ";
          const type=card["iType"] || "toshimon";
          pack.push({
            id,
            name,
            index,
            type,
          })
        }
        return <>
         
         <div className="pack">
           <div className="packText">Pack {i+1}</div>
           <div className="packContainer">
           {pack.map((c, i) => {
             return <div className={i == 7 ? "itemPack rare"  : "itemPack" }>
             <Card
               id={c.id}
               name={c.name}
               index={c.index}
               type={c.type}
             />
           </div>
           })}
           </div>

          </div>
          
        </>
      })}
    </>
  );
};

export default Cards;