import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from 'components/Header';


import ToshiProvider from 'contexts/Toshi';
import ModalsProvider from 'contexts/Modals';


import './css/animate.min.css'
import './css/bootstrap.css'
import './css/dashicons.css'
import './css/font-awesome.css'
import './css/main.css'
import './css/style.css'


import AppHome from 'pages/AppHome';
import Landing from 'pages/Landing';
import Dojo from 'pages/Dojo'
import Deck from 'pages/Deck'
import Play from 'pages/Play'
import Store from 'pages/Store'
import Footer from 'components/Footer';
import Fusion from 'pages/Fusion';
import PackContents from 'pages/PackContents';

const App = () => {


  return (
    <Providers >
      <div className="App">
      <Header/>
      <Switch>
        <Route path="/BuyPacks">
          <AppHome />
        </Route>
        <Route path="/OpenPacks">
          <Dojo />
        </Route>
        <Route path="/deck">
          <Deck />
        </Route>
        <Route path="/store">
          <Store />
        </Route>
        <Route path="/play">
          <Play />
        </Route>
        <Route path="/fusion">
          <Fusion />
        </Route>
        <Route path="/pack/:id/:quantity">
          <PackContents />
        </Route>
        <Route path="/">
          <Deck />
        </Route>
      </Switch>
      <Footer />
      </div>
    </Providers>
  );
};

const Providers: React.FC = ({ children }) => {
  const _window: any = window;
  const chainId = _window.ethereum ? _window.ethereum.chainId : 4;
 
  return (


        <ToshiProvider>
          <Router>
            <ModalsProvider>{children}</ModalsProvider>
          </Router>
        </ToshiProvider>

  );
};

export default App;
