import BigNumber from 'bignumber.js/bignumber'

import ERC20Abi from './abi/erc20.json'
import ToshiCoinAbi from './abi/toshiCoin.json'
import ToshiCashAbi from './abi/toshiCash.json'
import MooncatMinterAbi from './abi/mooncarMinter.json'
import wethAbi from './abi/wethAbi.json'
import ToshimonFusionAbi from './abi/toshimonFusion.json'
import ToshiCashFarmAbi from './abi/toshiCashFarm.json'
import ToshiDojoAbi from './abi/toshiDojo.json'
import ToshimonMinterAbi from "./abi/toshimonMinter.json"
import toshimonMinterMaticABI from "./abi/toshimonMinterMatic.json"
import ToshimonVendorABI from "./abi/toshimonVendor.json"
import ToshimonVendorFreeABI from "./abi/toshimonVendorFree.json"
import ToshimonVendorBeeABI from "./abi/toshimonVendoBee.json"
import ToshimonStakeContractABI from "./abi/toshimonStakingContract.json"
import ToshimonDojoRootTunnelABI from "./abi/ToshimonDojoRootTunnel.json"
import { ethers } from 'ethers'

import {
    contractAddresses,
} from './constants.js'

export class Contracts {
    constructor() {
        const networkId = 1;
        const provider = new ethers.providers.Web3Provider(window.ethereum)

        this.toshi = new ethers.Contract(contractAddresses.toshi[networkId], ERC20Abi,  provider);
        this.toshiEthLp = new ethers.Contract(contractAddresses.toshiEthLp[networkId], ERC20Abi,  provider);
        this.toshiCoin = new ethers.Contract(contractAddresses.toshiCoin[networkId], ToshiCoinAbi,  provider);
        this.toshiCash = new ethers.Contract(contractAddresses.toshiCash[networkId], ToshiCashAbi,  provider);
        this.mooncatMinter = new ethers.Contract(contractAddresses.mooncatMinter[networkId], MooncatMinterAbi,  provider);
        this.weth = new ethers.Contract(contractAddresses.weth[networkId], wethAbi,  provider);
        this.toshimonFusion = new ethers.Contract(contractAddresses.toshimonFusion[networkId], ToshimonFusionAbi,  provider);
        this.toshiCashFarm = new ethers.Contract(contractAddresses.toshiCashFarm[networkId], ToshiCashFarmAbi,  provider);
        this.toshiDojo = new ethers.Contract(contractAddresses.toshiDojo[networkId], ToshiDojoAbi,  provider);
        this.toshimonMinter = new ethers.Contract(contractAddresses.toshimonMinter[networkId], ToshimonMinterAbi,  provider);
        this.toshimonMinterMatic = new ethers.Contract(contractAddresses.toshimonMinterMatic[networkId], toshimonMinterMaticABI,  provider);
        this.toshimonVendor = new ethers.Contract(contractAddresses.toshimonVendor[networkId], ToshimonVendorABI,  provider);
        this.toshimonVendorBee = new ethers.Contract(contractAddresses.toshimonVendorBee[networkId], ToshimonVendorBeeABI,  provider);
        this.toshimonStakeContract = new ethers.Contract(contractAddresses.toshimonStakeContract[networkId], ToshimonStakeContractABI,  provider);
        this.toshimonVendorFree = new ethers.Contract(contractAddresses.toshimonVendorFree[networkId], ToshimonVendorFreeABI,  provider);
        this.toshimonDojoRootTunnel = new ethers.Contract(contractAddresses.toshimonDojoRootTunnel[networkId], ToshimonDojoRootTunnelABI,  provider);

        
    
       
    }

}
