import React, { createContext, useCallback, useEffect, useState } from 'react';
import web3 from 'web3';
import { OpenSeaPort, Network } from 'opensea-js'
import {ethers, VoidSigner} from 'ethers';
import { Order, OrderSide } from 'opensea-js/lib/types'

import { Toshi } from 'toshi';
import {
  getToshiBalance,
  getClaimableMooncat,
  getPolyBalance,
  getToshiCashBalance,
  getToshiCardRemaining,
  getMarenBalance,
  getPolymonBalance,
  getCobieBalance,
  getAllowanceBee,
  getStakePoolClaimableToshiCash,
  getStakePoolTokenBalanceToshiCash,


  getStakedToshimon,
  getClaimableToshiCash,
  getToshimonFusionAllowance,
  redeemPack as _redeemPack,
  redeemPackMatic as _redeemPackMatic,
  redeemCard as _redeemCard,
  redeemCardFree as _redeemCardFree,
  redeemCardBee as _redeemCardBee,

  approveFusion as _approveFusion,
  fuse as _fuse,
  mintMooncat as _mintMooncat,
  mintCUDl as _mintCUDl,
  mintMooncatToshicash as _mintMooncatToshicash,
  depositToshimonStakePool as _depositToshimonStakePool,
  withdrawToshimonStakePool as _withdrawToshimonStakePool,
  harvestToshimon as _harvestToshimon,
  
  approveBee as _approveBee,
  getToshimonBalance,
  getToshimonBalanceMatic,

  getLPBalance,
  getToshiPackBalance,
} from 'toshi/utils';

export interface ToshiContext {
  toshi?: typeof Toshi;
  account?: any;
  hasLoaded: boolean;
  toshiLoaded: boolean;
  loading: boolean;
  toshiLoading: boolean;
  toshiBalance?: string;
  nextCat?: number;
  claimableToshiCash?: string;
  curAccount?: string;
  toshimonBalance?: string;
  toshimonBalanceMatic?: string;
  toshimonLPBalance?: string;
  toshiPackBalance?: string;


  toshiCashBalance?: string;
  polyBalance?: boolean;
  cobie1Remaining?: string;
  cobie2Remaining?: string;
  neramRemaining?: string;
  marenRemaining?: string;
  polymonBalance?: string;
  cobieBalances?: string[];
  allowanceFusion?: string;

  tokenBalanceToshiCash?: string;

  allowanceBee?: string;
  marenBalance?: string;
  babycobie2Remaining?: string;

  pack?: string;

  stakeToshimon?:any;
  fetchToshimonBalance: (id: number) => void;


  approveFusion: () => void;
  fuse: (cardId: number, amount: string) => void;
  mintMooncat: (id: number) => void;
  mintCUDl: () => void;
  mintMooncatToshicash: () => void;

  redeemPack:(amount: string) => void;
  redeemPackMatic:(amount: string) => void;
  redeemCard:(id: number) => void;
  redeemCardBee:() => void;
  redeemCardFree:() => void;

  depositToshimonStakePool: (id: number, amount: string) => void;
  withdrawToshimonStakePool: (id: number, amount: string) => void;
  harvestToshimon:() => void;
  approveBee:  () => void;


  fetchToshimonStakePool:() => void;
}

export const Context = createContext<any>({
  toshi: undefined,
  account: undefined,
  hasLoaded: false,
  toshiLoaded: false,
  loading: false,
  toshiLoading: false,
  toshiBalance: undefined,
  nextCat: undefined,
  claimableToshiCash: undefined,
  curAccount: undefined,
  toshiPackBalance: undefined,
  toshimonBalance: undefined,
  toshimonBalanceMatic: undefined,
  toshimonLPBalance: undefined,
  toshiCoinBalance: undefined,
  wETHBalance: undefined,
  toshiCashBalance: undefined,
  polyBalance: undefined,
  cobie1Remaining: undefined,
  cobie2Remaining: undefined,
  babycobie2Remaining: undefined,
  neramRemaining: undefined,
  marenRemaining: undefined,
  polymonBalance: undefined,
  cobieBalances: undefined,
  allowanceFusion: undefined,
  allowanceToshiCash: undefined ,
  tokenBalanceToshiCash: undefined ,
  stakedBalanceToshiCash: undefined ,
  allowanceBee: undefined,

  marenBalance: undefined,
  pricePack: undefined,
  pack: undefined,
  priceInTOSHI: undefined,
  toshiCoinRemainingForSale: undefined,
  toshiCoinToshiAllowance: undefined,

  stakeToshimon:{}
});

const ToshiProvider: React.FC = ({ children }) => {

  const [toshi, setToshi] = useState<any>(undefined);
  const [state, setState] = useState<any>({
    account: undefined,
    activeAccount: undefined,
    hasLoaded: false,
    toshiLoaded: false,
    loading: false,
    toshiLoading: false,
    toshiBalance: undefined,
    nextCat: undefined,
    claimableToshiCash: undefined,
    curAccount: undefined,
    toshiPackBalance: undefined,
    toshimonBalance: undefined,
    toshimonBalanceMatic: undefined,
    toshimonLPBalance: undefined,
    toshiCoinBalance: undefined,
    wETHBalance: undefined,
    toshiCashBalance: undefined,
    polyBalance: undefined,
    cobie1Remaining: undefined,
    cobie2Remaining: undefined,
    babycobie2Remaining: undefined,
    neramRemaining: undefined,
    marenRemaining: undefined,
    polymonBalance: undefined,
    cobieBalances: undefined,
    allowanceFusion: undefined,
    allowanceToshiCash: undefined ,
    tokenBalanceToshiCash: undefined ,
    stakedBalanceToshiCash: undefined ,
    allowanceBee: undefined,
    marenBalance: undefined,
    pricePack: undefined,
    pack: undefined,
    priceInTOSHI: undefined,
    toshiCoinRemainingForSale: undefined,
    toshiCoinToshiAllowance: undefined,

    stakeToshimon: {}
  });



  useEffect(() => {
      // @ts-ignore
      connecty();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {

    connecty();
  
}, [state.account]);


const addNetwork = async () => {


  const networkData = [

        {

          chainId: "0x01",
        }

      ];

  // agregar red o cambiar red
//@ts-ignore
  return window.ethereum.request({

    method: "wallet_addEthereumChain",

    params: networkData,

  });

}

const checkChainId = async () => {
  //@ts-ignore
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const { chainId } = await provider.getNetwork()
  if(chainId !== 1 && chainId !== 4){
    setState({ ...state, wrongNetwork: true });
    addNetwork()
    return false;
  } 
  return true;
}

const connecty = async () => {

  if (!state.connected) {
      //@ts-ignore
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      //@ts-ignore
      provider.on("network", (newNetwork, oldNetwork) => {
          // When a Provider makes its initial connection, it emits a "network"
          // event with a null oldNetwork along with the newNetwork. So, if the
          // oldNetwork exists, it represents a changing network
          if (oldNetwork) {
              window.location.reload();
          }
      });
      //@ts-ignore
      window.ethereum.on("accountsChanged", (account) => {
        // When a Provider makes its initial connection, it emits a "network"
        // event with a null oldNetwork along with the newNetwork. So, if the
        // oldNetwork exists, it represents a changing network
        
            window.location.reload();
        
    });
    if(await checkChainId()){
      try{
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const account = await signer.getAddress();
        setState({ ...state, account, activeAccont: state.account, connected: true });

      } catch {}
    }
  }
    // Prompt user for account connections
   
}

  // @ts-ignore
  window.toshi = toshi;
  // @ts-ignore






  useEffect(() => {
    if (state.connected) {
      const toshiLib = new Toshi();

      setToshi(toshiLib);
    }
  }, [state.connected]);

  

  useEffect(() => {
    console.log(state.connected);
    console.log(state.account)


    if (toshi && state.account && state.connected) {
      const isLoading = state.loading;
      (async () => {
        if(!isLoading && isLoading !== undefined){
        const toshiBalance = (Number(await getToshiBalance(toshi, state.account)) / 1e18).toString();;
        const nextCat = Number(await getClaimableMooncat(toshi, state.account))

        const toshiCashBalance = (Number(await getToshiCashBalance(toshi, state.account)) / 1e18).toString();;
        const polyBalance = await getPolyBalance(toshi);
        

        const cobie1Remaining = await getToshiCardRemaining(toshi,319);
        const cobie2Remaining = await getToshiCardRemaining(toshi,320);
        const babycobie2Remaining = await getToshiCardRemaining(toshi,316);
        //const marenRemaining = await getCountOpen(317);
        const neramRemaining = await getToshiCardRemaining(toshi,318);
        
        const toshimonBalance = await getToshimonBalance(toshi,state.account);
       const toshimonBalanceMatic = await getToshimonBalanceMatic(toshi,state.account);
        const toshimonLPBalance = await getLPBalance(toshi,state.account);
        const marenRemaining = await getMarenBalance(toshi,"0xed792b394ec3aa3bdfa3a421b664ebd885466f71");
        const polymonBalance = await getPolymonBalance(toshi,state.account);
        const allowanceFusion  = await getToshimonFusionAllowance(toshi,state.account);
        const cobieBalances = await getCobieBalance(toshi,state.account);
        
        const toshiPackBalance = await getToshiPackBalance(toshi,state.account)

        const claimableToshiCash  = (Number(await getStakePoolClaimableToshiCash(toshi,state.account)) / 1e18).toString();
        const tokenBalanceToshiCash  = await getStakePoolTokenBalanceToshiCash(toshi,state.account,1);

  
        const allowanceBee = await getAllowanceBee(toshi,state.account)
  
   

        let pack: any;


          
        const pricePack = pack ? web3.utils.fromWei(String(pack.currentPrice)) : null;
        //fetchToshimonStakePool();

        setState((...state: any) => ({
          ...state,
          claimableToshiCash,
          activeAccount: state.account,
          hasLoaded: true,
          toshiBalance,
          nextCat,
          curAccount: state.account,
          tokenBalanceToshiCash,
          toshiCashBalance,
          polyBalance,
          toshimonBalance,
          toshimonBalanceMatic,
          toshimonLPBalance,
          toshiPackBalance,
          cobie1Remaining,
          cobie2Remaining,
          babycobie2Remaining,
          pricePack,
          pack,
          neramRemaining,
          marenRemaining,
          polymonBalance,
          cobieBalances,
          allowanceFusion,

          allowanceBee
          

       
          
          
        }));
      
      
      
      
        }
      })();
    }
  }, [toshi, state.account, state.hasLoaded, state.conected]);

  useEffect(() => {

    if (toshi && state.account && !state.hasLoaded && !state.toshiLoading) {
      const isLoading = state.toshiLoading;
      (async () => {
        if(!isLoading && isLoading !== undefined){
        const toshimonBalance = await getToshimonBalance(toshi,state.account);
        const toshimonBalanceMatic = await getToshimonBalanceMatic(toshi,state.account);



        setState((...state: any) => ({
          ...state,
          activeAccount: state.account,
          toshiLoaded: true,   
          toshimonBalance,
          toshimonBalanceMatic       
        }));
      }
      })();
    }

  }, [toshi, state.account, state.toshiLoaded]);

  //useInterval(fetchToshimon,10000);
  //useInterval(getValues, 2000);
  
 
  const fetchStakePoolToshiCash  = toshi
  ? async (poolId: number) => {

      const claimableToshiCash  = await getStakePoolClaimableToshiCash(toshi,state.account);
      const tokenBalanceToshiCash  = await getStakePoolTokenBalanceToshiCash(toshi,state.account,1);

      const allowanceBee = await getAllowanceBee(toshi,state.account)
      console.log("boo")
 
      setState(( ...state : any) => ({
        ...state,

        claimableToshiCash ,
        allowanceBee,
        tokenBalanceToshiCash

      }));
    }
  : () => {};
 const fetchToshimonStakePool  = toshi
 ? async () => {

     const perDayToshiCashBee  = "3000";
     const perDayToshiCashGrey  = "1000";
     const beebalance  = await getStakedToshimon(toshi,state.account,696 );
     const greybalance  = await getStakedToshimon( toshi,state.account, 888);
     const stakedtoshimonLPBalance = {
       bee:beebalance,
       grey: greybalance
      }
     const claimableToshiCash  = await getClaimableToshiCash(toshi,state.account);
     console.log(stakedtoshimonLPBalance)

     setState(({ stakeToshimon, ...state }: any) => ({
       ...state,
       stakeToshimon: {
         ...stakeToshimon,

           stakedtoshimonLPBalance,
           claimableToshiCash ,
           perDayToshiCashBee, 
           perDayToshiCashGrey
       },
     }));
   }
 : () => {};


  const approveFusion = toshi
    ? async (poolId: number) => {
        try {
          await _approveFusion(toshi, state.account);
        } catch (err) {
          console.log('Error approving stake pool: ', err);
        }
      }
    : () => {};
  const approveBee = toshi
    ? async () => {
        try {
          await _approveBee(toshi, state.account);
        } catch (err) {
          console.log('Error approving stake pool: ', err);
        }

        fetchStakePoolToshiCash(1);
      }
    : () => {};
    

  
  const fuse = toshi
    ? async (cardId: number, amount: number) => {
        try {
          await _fuse(toshi, state.account, amount, cardId);
        } catch (err) {
          console.log('Error depositing to stake pool: ', err);
        }

      
        setState(({ ...state }) => ({ ...state, hasLoaded: false }));
      }
    : () => {};
  const mintMooncat = toshi ? async (id: number) => {
    
    try {
      await _mintMooncat(toshi, id);
    } catch (err) {
      console.log('Error depositing to stake pool: ', err);
    }

  
    setState(({ ...state }) => ({ ...state, hasLoaded: false }));
  }
: () => {};

const mintMooncatToshicash
= toshi ? async () => {
    
  try {
    await _mintMooncatToshicash(toshi);
  } catch (err) {
    console.log('Error depositing to stake pool: ', err);
  }


  setState(({ ...state }) => ({ ...state, hasLoaded: false }));
}
: () => {};

const mintCUDl = toshi ? async () => {
    
  try {
    await _mintCUDl(toshi);
  } catch (err) {
    console.log('Error depositing to stake pool: ', err);
  }


  setState(({ ...state }) => ({ ...state, hasLoaded: false }));
}
: () => {};

  const redeemPack = toshi
    ? async (amount: string) => {
      let temp = "failed";
        try {
          console.log("DDDDDDDUUUUDEEEE");
          let resp = await _redeemPack(toshi, state.account, amount);
          console.log(resp);
          temp = "success"
        } catch (err) {
          console.log('Error depositing to stake pool: ', err);
        }
        

    
        setState(({ ...state }) => ({ ...state, hasLoaded: false }));

      }
    : () => {};

  const redeemPackMatic = toshi
  ? async (amount: string) => {
    let resp;
      try {
        resp = await _redeemPackMatic(toshi, state.account, amount);
      } catch (err) {
        console.log('Error depositing to stake pool: ', err);
      }
      //setState(({ ...state }) => ({ ...state, hasLoaded: false }));
      return resp ? {
        id:Number(resp.events[1].raw.topics[1]), 
        quantity: Number(amount) * 8
      } : {
        id:0, 
        quantity: 0
      };

    }
  : () => {};
  const redeemCard = toshi
    ? async (id: number) => {
      let temp = "failed";
        try {
          console.log("DDDDDDDUUUUDEEEE");
          let resp = await _redeemCard(toshi, state.account, id);
          
          console.log(resp);
          temp = "success"
        } catch (err) {
          console.log('Error depositing to stake pool: ', err);
        }
        

    
        setState(({ ...state }) => ({ ...state, hasLoaded: false }));

      }
    : () => {};
    const redeemCardFree = toshi
    ? async (id: number) => {
      let temp = "failed";
        try {
          console.log("DDDDDDDUUUUDEEEE");
          let resp = await _redeemCardFree(toshi);
          
          console.log(resp);
          temp = "success"
        } catch (err) {
          console.log('Error depositing to stake pool: ', err);
        }
        

    
        setState(({ ...state }) => ({ ...state, hasLoaded: false }));

      }
    : () => {};
   
    const redeemCardBee = toshi
    ? async (id: number) => {
      let temp = "failed";
        try {
          console.log("DDDDDDDUUUUDEEEE");
          let resp = await _redeemCardBee(toshi, state.account, id);
          
          console.log(resp);
          temp = "success"
        } catch (err) {
          console.log('Error depositing to stake pool: ', err);
        }
        

    
        setState(({ ...state }) => ({ ...state, hasLoaded: false }));

      }
    : () => {};

    

  

    const depositToshimonStakePool = toshi
    ? async (id: string, amount: string) => {
        try {
          await _depositToshimonStakePool(toshi, state.account, amount, id);
        } catch (err) {
          console.log('Error depositing to stake pool: ', err);
        }

        fetchToshimonStakePool();
        setState(({ ...state }) => ({ ...state, hasLoaded: false }));
      }
    : () => {};
    const withdrawToshimonStakePool = toshi
    ? async (id: string, amount: string) => {
        try {
          await _withdrawToshimonStakePool(toshi, state.account, amount, id);
        } catch (err) {
          console.log('Error withdrawing from stake pool: ', err);
        }

        fetchToshimonStakePool();
        setState(({ ...state }) => ({ ...state, hasLoaded: false }));
      }
    : () => {};
    const harvestToshimon = toshi
    ? async () => {
        try {
          await _harvestToshimon(toshi, state.account);
        } catch (err) {
          console.log('Error withdrawing from stake pool: ', err);
        }

        fetchToshimonStakePool();
        setState(({ ...state }) => ({ ...state, hasLoaded: false }));
      }
    : () => {};
    



  return (
    <Context.Provider
      value={{
        toshi,
        ...state,

     
        fetchStakePoolToshiCash,
        fetchToshimonStakePool,
      
        approveFusion,
        fuse,
        mintMooncat,
        mintCUDl,
        mintMooncatToshicash,
        depositToshimonStakePool,
        withdrawToshimonStakePool,
        harvestToshimon,
        approveBee,

        redeemPack,
        redeemPackMatic,
        redeemCard,
        redeemCardFree,
        redeemCardBee,

        connecty

      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ToshiProvider;
