import React, { useEffect, useState } from 'react';
import axios from 'axios'
import styled from 'styled-components';

import { useToshi } from 'hooks';

import Modal from 'react-modal';
import Button from 'components/Button';

import ToshiJSON from 'components/json/toshimon.json'

Modal.setAppElement('#root')

function getCardAttr (card: any, attribute: String) {
  try{
  return card.attributes.find((attr: { trait_type: String; }) => attr.trait_type === attribute).value
  }
  catch{
    return false;
  }
}

const InsetImage = styled.img`
  padding: 5px;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin: inheret auto;
  background-color: rgb(240, 233, 231);
  box-shadow: rgb(226, 214, 207) 4px 4px 8px inset,
    rgb(247, 244, 242) -6px -6px 12px inset;
`; 

const ArrowRight = styled.div`
position: absolute;
padding: 1rem; /* Arrow size */
box-shadow: 1px -1px 0 1px plum inset;
-webkit-box-shadow: 2px -2px plum inset;
border: solid transparent;
border-width: 0 0 2rem 2rem;
transition: 0.2s;
transform:rotate(225deg);
top:calc(50% - 1rem);
right:-1.75em;
display:none;


  
  &:hover {
    box-shadow: 2px -2px 0 2px orchid inset;
    -webkit-box-shadow: 4px -4px orchid inset;
    
  
  }
`;
const ArrowLeft = styled.div`
position: absolute;
padding: 1rem; /* Arrow size */
box-shadow: 1px -1px 0 1px plum inset;
-webkit-box-shadow: 2px -2px plum inset;
border: solid transparent;
border-width: 0 0 2rem 2rem;
transition: 0.2s;
transform:rotate(45deg);
top:calc(50% - 1rem);
left:-1.75em;
display:none;
  
  &:hover {
    box-shadow: 2px -2px 0 2px orchid inset;
    -webkit-box-shadow: 4px -4px orchid inset;
    }
  
  
`;
const Hover = styled.div`
  position:relative;
    
  &:hover ${ArrowLeft} {
    display: block;
  }
  &:hover ${ArrowRight} {
    display: block;
  }
`;

const CardModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgb(204, 204, 204);
`

const CardModalTitle = styled.h3`
  margin: 0;
`

const CardModalImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 1rem;
`

const CardModalDesc = styled.p`
  color: #606060;
`

// const CardModalStatTable = styled.dt`
//   margin-top: 1rem;
//   margin-bottom: 2rem;
//   font-size: 0.75em;
// `

interface card {
  type:string
  name:string
  id:number
  index:number
}



const Card: React.FC<card> = ({
  type,
  name,
  id,
  index
}) => {

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [selectedCardData, setSelectedCardData] = useState<any>(null);
  const [isFetchingCardData, setIsFetchingCardData] = useState<boolean>(false);

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }
  
  const afterCloseModal = () => {
    setSelectedCardData(null)
  }

  useEffect(() => {
    if(modalIsOpen ){
      setIsFetchingCardData(true)
      axios.get(`https://api.toshimon.io/cards/${index}`)
      .then(data => {
        setSelectedCardData(data.data);
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        setIsFetchingCardData(false)
      })
    }
  }, [modalIsOpen])
  
  return (
    <div >
        
          
            
      <Hover className="">
      <img
        onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
        onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
        style={{cursor:"pointer",borderRadius: "5%/4%"}}
        src={ type == "toshimon" ? "https://api.toshimon.io/Cards/"+id+"/"+id+"-0.png" : type == "Demeanor" ? "https://api.toshimon.io/Demeanor/"+id+".png"  : "https://api.toshimon.io/"+type+"s/"+id+".png" }
        onClick={openModal}
        alt={name}
        
        
      />
      </Hover>
            
            
      <Modal
        isOpen={modalIsOpen}
        onAfterClose={afterCloseModal}
        onRequestClose={closeModal}
        style={{
          content: {
            maxWidth: '90vw',
            margin: 'auto'
          }
        }}
      >
        {selectedCardData && !isFetchingCardData ? (
          <article>
            <CardModalHeader>
              <CardModalTitle>{selectedCardData.name}</CardModalTitle>
              <div>#{getCardAttr(selectedCardData, 'Number')}</div>
            </CardModalHeader>

            <div className="clearfix">
              <div className="col-sm-6 text-center">
                <CardModalImg src={selectedCardData.image} alt={`${selectedCardData.name} card`} style={{maxWidth:"100%", maxHeight:"80vh"}}/>
                
              </div>

              <div className="col-sm-6">
                <CardModalDesc>{selectedCardData.description}</CardModalDesc>
                <div className="clearfix text-center">
                  {getCardAttr(selectedCardData, "HP") && ['HP', 'Speed', 'Attack', 'Sp. Atk', 'Defense', 'Sp. Def'].map(stat => (
                    <div className="col-xs-4 col-sm-6" style={{marginBottom: '1rem'}}>
                      <dt>
                        {stat}
                      </dt>
                      <dd style={{color: '#303030'}}>
                        {getCardAttr(selectedCardData, stat)}
                      </dd>
                    </div>
                  ))}
                </div> 
              </div>
            </div>
          </article>
        ) : (
          <div className="text-center" style={{padding: '1rem'}}>Loading...</div>
        )}
      </Modal>
    </div>
  );
};

export default Card;