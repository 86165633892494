import { get } from 'lodash'
import { ethers } from 'ethers'
import web3 from 'web3';

import ERC20Abi from "./lib/abi/erc20.json";

import Matic from '@maticnetwork/maticjs'
import ToshimonDojoChildTunnelABI from "./lib/abi/ToshimonDojoChildTunnel.json"



const TIME_BETWEEN_REQUESTS = 5 * 60 * 1000; // 5 minutes

const tokenPrices = {};
const lastTokenCheck = {};


// Create sdk instance
const matic = new Matic({
    network: "mainnet",
    version: "v1",
    maticProvider: "https://matic-mainnet.chainstacklabs.com", // replace if using mainnet
    parentProvider: "https://rpc.slock.it/mainnet",
    
})

// init matic

matic.initialize()


export const getPrice = async (coinName) => {
    const geckoUrl = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${coinName}`;
    const lastCheck = lastTokenCheck[coinName];

    if (!lastCheck || new Date() - lastCheck > TIME_BETWEEN_REQUESTS) {
        try {
            const result = await fetch(geckoUrl);
            const priceJson = await result.json();

            tokenPrices[coinName] = Number(get(priceJson, '0.current_price'));
            lastTokenCheck[coinName] = new Date();

            return tokenPrices[coinName];
        } catch (err) {
            console.log(`Error fetching price for ${coinName}:`, err);

            tokenPrices[coinName] = Number(1);
            lastTokenCheck[coinName] = new Date();
        }
    }

    return tokenPrices[coinName] || 1;
}

export const getToshiPrice = async () => {
    return getPrice('toshi');
}

// Addresses

export const getToshiAddress = (toshi) => {
    return get(toshi, 'toshiAddress');
}
export const getMooncatMinterAddress = (toshi) => {
    return get(toshi, 'mooncatMinterAddress');
}

export const getToshiEthLpAddress = (toshi) => {
    return get(toshi, 'toshiEthLpAddress');
}

export const getToshiCoinAddress = (toshi) => {
    return get(toshi, 'toshiCoinAddress');
}
export const getToshiCashAddress = (toshi) => {
    return get(toshi, 'toshiCashAddress');
}

export const getToshimonFusionAddress = (toshi) => {
    return get(toshi, 'toshimonFusionAddress');
}
export const getToshiCashFarmAddress = (toshi) => {
    return get(toshi, 'toshiCashFarmAddress');
}

export const getToshiDojoAddress = (toshi) => {
    return get(toshi, 'toshiDojoAddress');
}

export const getToshimonMinterAddress = (toshi) => {
    return get(toshi, 'toshimonMinterAddress');
}
export const getToshimonVendorAddress = (toshi) => {
    return get(toshi, 'toshimonVendorAddress');
}
export const getToshimonVendorFreeAddress = (toshi) => {
    return get(toshi, 'toshimonVendorFreeAddress');
}
export const getToshimonVendorBeeAddress = (toshi) => {
    return get(toshi, 'toshimonVendorBeeAddress');
}

export const getToshimonDojoRootTunnelAddress = (toshi) => {
    return get(toshi, 'toshimonDojoRootTunnelAddress');
}

// Contracts


export const getToshiContract = (toshi) => {
    return get(toshi, 'contracts.toshi');
}

export const getToshiEthLpContract = (toshi) => {
    return get(toshi, 'contracts.toshiEthLp');
}

export const getToshiCoinContract = (toshi) => {
    return get(toshi, 'contracts.toshiCoin');
}
export const getToshiCashContract = (toshi) => {
    return get(toshi, 'contracts.toshiCash');
}
export const getWETHContract = (toshi) => {
    return get(toshi, 'contracts.weth');
}

export const getToshimonFusionContract = (toshi) => {
    return get(toshi, 'contracts.toshimonFusion');
}
export const getMooncatMinterContract = (toshi) => {
    return get(toshi, 'contracts.mooncatMinter');
}
export const getToshimonStakeContract = (toshi) => {
    return get(toshi, 'contracts.toshimonStakeContract');
}

export const getToshiCashFarmContract = (toshi) => {
    return get(toshi, 'contracts.toshiCashFarm');
}

export const getToshiDojoContract = (toshi) => {
    return get(toshi, 'contracts.toshiDojo');
}

export const getToshimonMinterContract = (toshi) => {
    return get(toshi, 'contracts.toshimonMinter');
}
export const getToshimonMinterContractMatic = (toshi) => {
    return get(toshi, 'contracts.toshimonMinterMatic');
}

export const getToshimonVendorContract = (toshi) => {
    return get(toshi, 'contracts.toshimonVendor');
}
export const getToshimonVendorFreeContract = (toshi) => {
    return get(toshi, 'contracts.toshimonVendorFree');
}
export const getToshimonVendorBeeContract = (toshi) => {
    return get(toshi, 'contracts.toshimonVendorBee');
}

export const getToshimonDojoRootTunnelContract = (toshi) => {
    return get(toshi, 'contracts.toshimonDojoRootTunnel');
}





// Balances

export const getToshiBalance = async (toshi, account) => {
    const toshiContract = getToshiContract(toshi);
    const balance = await toshiContract.balanceOf(account);
    return (balance).toString();;
}

export const getToshiEthLpBalance = async (toshi, account) => {
    const toshiEthLpContract = getToshiEthLpContract(toshi);
    const balance = await toshiEthLpContract.balanceOf(account);
    return (balance).toString();;
}

export const getToshiCashBalance = async (toshi, account) => {
    const toshiCashContract = getToshiCashContract(toshi);
    const balance = await toshiCashContract.balanceOf(account);
    return (balance).toString();;
}
export const getWETHBalance = async (toshi, account) => {
    const wETHContract = getWETHContract(toshi);

    const balance =  await wETHContract.balanceOf(account);
    return (balance).toString();;
}
export const getToshiCardRemaining = async (toshi,cardId) => {
    const toshimonMinterContract = getToshimonMinterContract(toshi);
    const balance = await toshimonMinterContract.tokenSupply(cardId);
    return balance.toString();
}

export const getToshiCoinBalance = async (toshi, account) => {
    const toshiCoinContract = getToshiCoinContract(toshi);
    const balance = await toshiCoinContract.balanceOf(account);
    return (balance).toString();;
}

export const getPolyBalance = async (toshi) => {
    const contract = getToshimonVendorFreeContract(toshi);
    const used = await contract.addressUsed();
    return used;
}



export const getStakePoolInfoToshiCash = async (toshi, poolId) => {
    const toshiCashFarmContract = getToshiCashFarmContract(toshi);
    const poolInfo = await toshiCashFarmContract.poolInfo(poolId);
    return poolInfo;
}





export const getToshimonFusionAllowance = async (toshi, account, poolId) => {
    
    const token  = getToshiAddress(toshi);
    const toshimonFusionAddress = getToshimonFusionAddress(toshi);
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    console.log(token)
    const tokenContract = new ethers.Contract(token, ERC20Abi, provider);
    
    const allowance = await tokenContract
        
        .allowance(account, toshimonFusionAddress);
    
    return (allowance).toString();
}
export const getAllowanceBee = async (toshi, account) => {
    console.log(1111)
    const { token } = await getStakePoolInfoToshiCash(toshi, 1);
    //const beeAddress = getToshimonVendorBeeAddress(toshi);
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    console.log(token)
    const tokenContract = new ethers.Contract(token, ERC20Abi, provider);
    
    const allowance = await tokenContract.allowance(account, "0xA833645635409Fc7Bdd5d9C5305CA1c2e6233eA8");
    
    return (allowance).toString();
}

export const getStakedToshimon = async (toshi, account, id) => {
    const toshimonStakeContract = getToshimonStakeContract(toshi);
    const balance = await toshimonStakeContract.userCards(account, id);
    return (balance).toString();
}
export const getClaimableToshiCash = async (toshi, account ) => {
    const toshimonStakeContract = getToshimonStakeContract(toshi);
    const claimable = await toshimonStakeContract.totalPendingToshicashOfAddress(account,"false")
    return (claimable).toString();;
}



export const getStakePoolClaimableToshiCash = async (toshi, account) => {
    const toshimonStakeContract = getToshimonStakeContract(toshi);
    const claimable = await toshimonStakeContract.totalPendingToshicashOfAddress(account,"false")
    return (claimable).toString();
}
export const getToshiCashBonus = async (toshi, account, poolId) => {
    const toshiCashFarmContract = getToshiCashFarmContract(toshi);
    let claimable = "0"
    try { claimable = await toshiCashFarmContract.pendingCoinsBonus(account) } catch{}
    return (claimable).toString();
}
export const claimToshiCashBonus = async (toshi, account) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshiCashFarmContract = getToshiCashFarmContract(toshi).connect(signer);

    const reciept = await (await toshiCashFarmContract.claimBonus()).wait();
    console.log(reciept);
}

// Stake TOSHI LP Mutations

export const approveFusion = async (toshi, account) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const tokenContract = getToshiContract(toshi).connect(signer);
    const toshimonFusionAddress = getToshimonFusionAddress(toshi);
    const reciept = await (await tokenContract.approve(toshimonFusionAddress, ethers.constants.MaxUint256)).wait();
    console.log(reciept);
    
}

export const fuse = async (toshi, account, amount, cardId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshimonFusionContract = getToshimonFusionContract(toshi).connect(signer);
    
    const reciept = await (await toshimonFusionContract.fuse(cardId, amount)).wait();
    console.log(reciept);
}
export const getClaimableMooncat = async (toshi, account ) => {
    console.log(111111)
    const contract = getMooncatMinterContract(toshi);
    const id = await contract.nextCat(account);
    console.log(id)
    return id;
}
export const mintMooncat = async (toshi, id) => { 

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    const contract = getMooncatMinterContract(toshi).connect(signer);
    const reciept = await (await contract.mintMooncat(id)).wait();
    console.log(reciept);
}
export const mintMooncatToshicash = async (toshi) => { 

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    const contract = getMooncatMinterContract(toshi).connect(signer);
    const reciept = await (await contract.mintMooncatToshicash()).wait();
    console.log(reciept);
}

export const mintCUDl = async (toshi) => { 

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    const contract = getMooncatMinterContract(toshi).connect(signer);
    const reciept = await (await contract.mintCudl(1, {value:"100000000000000000"})).wait();
    console.log(reciept);
}

export const depositToshimonStakePool = async (toshi, account, amount, id) => {
    console.log(1)
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshimonStakeContract = getToshimonStakeContract(toshi).connect(signer);


    const reciept = await (await toshimonStakeContract.stake([id], [amount])).wait();
    console.log(reciept);
}

export const withdrawToshimonStakePool = async (toshi, account, amount, id) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshimonStakeContract = getToshimonStakeContract(toshi).connect(signer);

    const reciept = await (await toshimonStakeContract.unstake([id], [amount])).wait();
    console.log(reciept);
}
export const harvestToshimon = async (toshi, account) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshimonStakeContract = getToshimonStakeContract(toshi).connect(signer);

    const reciept = await (await toshimonStakeContract.harvest()).wait();
    console.log(reciept);
}



export const harvestStakePool = async (toshi, account, poolId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshiCashFarmContract = getToshiCashFarmContract(toshi).connect(signer);

    const reciept = await (await toshiCashFarmContract.withdraw(poolId, 0)).wait();
    console.log(reciept);
}
export const approveStakePoolToshiCash = async (toshi, account, poolId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshiCashFarmAddress = getToshiCashFarmAddress(toshi).connect(signer);
    const poolInfo = await getStakePoolInfoToshiCash(toshi, poolId);
    const tokenContract = new ethers.Contract( poolInfo.token, ERC20Abi, provider).connect(signer);

    const reciept = await (await  tokenContract.approve(toshiCashFarmAddress, ethers.constants.MaxUint256)).wait();
    console.log(reciept);
}
export const approveBee = async (toshi, account) => {
    //const beeAddress = getToshimonVendorBeeAddress(toshi);
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const poolInfo = await getStakePoolInfoToshiCash(toshi, 1);
    console.log(poolInfo.token)
    const tokenContract = new ethers.Contract( poolInfo.token, ERC20Abi, provider).connect(signer);

    const reciept = await (await tokenContract.approve("0xA833645635409Fc7Bdd5d9C5305CA1c2e6233eA8", ethers.constants.MaxUint256)).wait();
    console.log(reciept);
}


export const depositStakePoolToshiCash = async (toshi, account, amount, poolId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshiCashFarmContract = getToshiCashFarmContract(toshi).connect(signer);
    const depositAmount = ethers.utils.formatUnits(String(amount), 'ether');


    const reciept = await (await toshiCashFarmContract.deposit(poolId, depositAmount)).wait();
    console.log(reciept);
}

export const withdrawStakePoolToshiCash = async (toshi, account, amount, poolId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshiCashFarmContract = getToshiCashFarmContract(toshi).connect(signer);
    const withdrawAmount = ethers.utils.formatUnits(String(amount), 'ether');

    const reciept = await (await toshiCashFarmContract.withdraw(poolId, withdrawAmount)).wait();
    console.log(reciept);
}


export const harvestStakePoolToshiCash = async (toshi, account, poolId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshiCashFarmContract = getToshiCashFarmContract(toshi).connect(signer);


    const reciept = await (await toshiCashFarmContract.withdraw(poolId, 0)).wait();
    console.log(reciept);
}

// Redeem and open packs



export const purchasePack = async (toshi, account, amount) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshiDojoContract = getToshiDojoContract(toshi).connect(signer);



    const reciept = await (await toshiDojoContract.purchasePack(amount)).wait();
    console.log(reciept);
}

export const redeemPack = async (toshi, account, amount) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshiDojoContract = getToshiDojoContract(toshi).connect(signer);
    


    const reciept = await (toshiDojoContract.redeemPack(amount)).wait();
    console.log(reciept);
}

export const getPackContents = () => {
    const child_provider = new web3.providers.WebsocketProvider(
        "wss://rpc-mainnet.matic.quiknode.pro"
    );
    const child_web3 = new web3(child_provider);

    child_web3.eth.handleRevert = true;

    let toshimonDojoChildTunnel = new child_web3.eth.Contract(ToshimonDojoChildTunnelABI);
    toshimonDojoChildTunnel.options.address = "0x91EC4095c74669958aE42Bb7ff7925C3668384A2"
    //test
    //toshimonDojoChildTunnel.options.address = "0x13351324fFBfd7940219F62155e47a80dF623E9F"

    return toshimonDojoChildTunnel;
}
/*
export const getPackContents = () => {
    const child_provider = new web3.providers.HttpProvider(
        "https://polygon-mainnet.infura.io/v3/3f7945f3464d4e1e8e0f2b31a402ce60"
    );
    const child_web3 = new web3(child_provider);

    child_web3.eth.handleRevert = true;

    let toshimonDojoChildTunnel = new child_web3.eth.Contract(ToshimonDojoChildTunnelABI,"0x91EC4095c74669958aE42Bb7ff7925C3668384A2");
    toshimonDojoChildTunnel.options.address = ""
    //test
    //toshimonDojoChildTunnel.options.address = "0x13351324fFBfd7940219F62155e47a80dF623E9F"

    toshimonDojoChildTunnel.getPastEvents(
        "allEvents",
        { fromBlock: 16177863, toBlock: "latest" },
        (errors, events) => {
            if (!errors) {
                // process events
                console.log(events);
            }
        }
    );

    return toshimonDojoChildTunnel;
}*/
export const redeemPackMatic = async (toshi, account, amount) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshimonDojoRootTunnelContract = getToshimonDojoRootTunnelContract(toshi).connect(signer);
    

    const reciept = await (await toshimonDojoRootTunnelContract.sendMessageToChild(amount)).wait();
    return reciept
}

export const redeemCard = async (toshi, account, id) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshimonVendorContract = getToshimonVendorContract(toshi).connect(signer);
    

    const reciept = await (await toshimonVendorContract.redeem(id)).wait();
    console.log(reciept);
}
export const redeemCardFree = async (toshi) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshimonVendorFreeContract = getToshimonVendorFreeContract(toshi).connect(signer);
   

            // send transaction
    const reciept = await (await toshimonVendorFreeContract.redeem()).wait();
    console.log(reciept);

    
}


export const redeemCardBee = async (toshi, account, id) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    // Prompt user for account connections
    const signer = provider.getSigner();
    const toshimonVendorBeeContract = getToshimonVendorBeeContract(toshi).connect(signer);
    

    const reciept = await (await toshimonVendorBeeContract.redeem(id)).wait();
    console.log(reciept);
}



// toshi card

export const getToshimonBalance = async (toshi, account) => {
    const toshimonMinterContract = getToshimonMinterContract(toshi);
    let accArray = []
    let idArray = []
    let ret = [];
    let balances = []
    for(let k = 0; k <= 323; k++){
        accArray.push(account)
        idArray.push(k)
        if(k%50 == 0 || k >= 323){
            balances = balances.concat((await toshimonMinterContract.balanceOfBatch(accArray,idArray)).map(s => s.toString()));
            accArray = [];
            idArray = [];
        }
    }
    return balances;
}
export const getToshimonBalanceMatic = async (toshi, account) => {
    const toshimonMinterContractMatic = matic.getPOSERC1155TokenContract('0x7658ab44641352046Bde4Aa6df9A62966F439893');
    let accArray = []
    let idArray = []
    let ret = [];
    let balances = []
    for(let k = 0; k <= 323; k++){
        accArray.push(account)
        idArray.push(k)
        if(k%50 == 0 || k >= 323){
            balances = balances.concat(await toshimonMinterContractMatic.methods.balanceOfBatch(accArray,idArray).call());
            accArray = [];
            idArray = [];
        }
    }
    return balances;
}
export const getPolymonBalance = async (toshi, account) => {
    const toshimonMinterContract = getToshimonMinterContract(toshi);
    const balance = toshimonMinterContract.balanceOf(account,9999)
    return (await balance).toString()
}
export const getCobieBalance = async (toshi, account) => {
    const toshimonMinterContract = getToshimonMinterContract(toshi);
    const balance319 = (await toshimonMinterContract.balanceOf(account,319)).toString()
    const balance320 = (await toshimonMinterContract.balanceOf(account,320)).toString()
    const balance321 = (await toshimonMinterContract.balanceOf(account,321)).toString()
    return [balance319, balance320, balance321];
}
export const getLPBalance = async (toshi, account) => {
    const toshimonMinterContract = getToshimonMinterContract(toshi);
    const toshimonStakeContract = getToshimonStakeContract(toshi);

    let balances = {bee:0,grey:0,beeStaked:0,greyStaked:0}

    balances.bee = (await toshimonMinterContract.balanceOf(account,888)).toString();
    balances.grey = (await toshimonMinterContract.balanceOf(account,696)).toString();
    balances.beeStaked = (await toshimonStakeContract.userCards(account,888)).toString();
    balances.greyStaked = (await toshimonStakeContract.userCards(account,696)).toString();

    return balances;
}
export const getMarenBalance = async (toshi, account) => {
    const toshimonMinterContract = getToshimonMinterContract(toshi);




    const balances = (await toshimonMinterContract.balanceOf(account,317)).toString();


    return balances;
}
export const getStakePoolTokenBalanceToshiCash = async (toshi, account, poolId) => {
    const { token } = await getStakePoolInfoToshiCash(toshi, poolId);
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const tokenContract = new ethers.Contract(token, ERC20Abi, provider);
    const balance = await tokenContract.balanceOf(account);
    return (balance).toString();
}

export const getToshiPackBalance = async (toshi, account) => {
    const toshimonMinterContract = getToshimonMinterContract(toshi);
    
    let balance = await toshimonMinterContract.balanceOf(account,0);

    
    return balance.toString()
}