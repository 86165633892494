import React, { useEffect, useState } from 'react';
import axios from 'axios'
import styled from 'styled-components';

import { useToshi } from 'hooks';

import Modal from 'react-modal';
import Button from 'components/Button';

import ToshiJSON from 'components/json/toshimon.json'

Modal.setAppElement('#root')

function getCardAttr (card: any, attribute: String) {
  return card.attributes.find((attr: { trait_type: String; }) => attr.trait_type === attribute).value
}

const InsetImage = styled.img`
  padding: 5px;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin: inheret auto;
  background-color: rgb(240, 233, 231);
  box-shadow: rgb(226, 214, 207) 4px 4px 8px inset,
    rgb(247, 244, 242) -6px -6px 12px inset;
`; 

const ArrowRight = styled.div`
position: absolute;
padding: 1rem; /* Arrow size */
box-shadow: 1px -1px 0 1px plum inset;
-webkit-box-shadow: 2px -2px plum inset;
border: solid transparent;
border-width: 0 0 2rem 2rem;
transition: 0.2s;
transform:rotate(225deg);
top:calc(50% - 1rem);
right:-1.75em;
display:none;


  
  &:hover {
    box-shadow: 2px -2px 0 2px orchid inset;
    -webkit-box-shadow: 4px -4px orchid inset;
    
  
  }
`;
const ArrowLeft = styled.div`
position: absolute;
padding: 1rem; /* Arrow size */
box-shadow: 1px -1px 0 1px plum inset;
-webkit-box-shadow: 2px -2px plum inset;
border: solid transparent;
border-width: 0 0 2rem 2rem;
transition: 0.2s;
transform:rotate(45deg);
top:calc(50% - 1rem);
left:-1.75em;
display:none;
  
  &:hover {
    box-shadow: 2px -2px 0 2px orchid inset;
    -webkit-box-shadow: 4px -4px orchid inset;
    }
  
  
`;
const Hover = styled.div`
  position:relative;
    
  &:hover ${ArrowLeft} {
    display: block;
  }
  &:hover ${ArrowRight} {
    display: block;
  }
`;

const CardModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgb(204, 204, 204);
`

const CardModalTitle = styled.h3`
  margin: 0;
`

const CardModalImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 1rem;
`

const CardModalDesc = styled.p`
  color: #606060;
`

// const CardModalStatTable = styled.dt`
//   margin-top: 1rem;
//   margin-bottom: 2rem;
//   font-size: 0.75em;
// `



const Cards: React.FC = () => {

  const [cardExpanded, setCardExpanded] = useState<any>({undefined});
  const [dogeExpanded, setDogeExpanded] = useState<any>({undefined});
  const [cardID, setCardId] = useState<any>({undefined});
  const [demCount, setDemCount] = useState<any>({undefined});
  const [cardArray, setCardArray] = useState<Array<any>>([]);
  const [hover, setHover] = useState<any>("start");
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [selectedCardId, setSelectedCardId] = useState<any>(null);
  const [selectedCardData, setSelectedCardData] = useState<any>(null);
  const [isFetchingCardData, setIsFetchingCardData] = useState<boolean>(false);

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }
  
  const afterCloseModal = () => {
    setSelectedCardData(null)
  }

  const handleClickCard = (cardId: Number) => {
    openModal();
    setSelectedCardId(cardId)
  }

  useEffect(() => {
    setIsFetchingCardData(true)
    axios.get(`https://api.toshimon.io/cards/${selectedCardId}`)
    .then(data => {
      setSelectedCardData(data.data);
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      setIsFetchingCardData(false)
    })
  }, [selectedCardId])

  const {
    priceInTOSHI,
  
    toshimonBalance,
    polymonBalance,
    toshimonBalanceMatic

  } = useToshi();



  useEffect(() => {
  
    setCardExpanded(null);
    setDogeExpanded(null);
    setHover(null);
    if (toshimonBalance) {
      let ret = [];
      for(let i = 312; i <= 320; i++){
        

       

          let cardBal = (Number(toshimonBalance[i]) || 0) + (Number(toshimonBalanceMatic[i]) || 0)
         
   
         
          const cardArr = ToshiJSON;
          let c:any = {}
          for(let ca in cardArr){
            if(cardArr[ca].Index == i+1){
              c = cardArr[ca];
              break;
            }
          }
          i < 313 ? cardBal > 0 &&
              ret.push({
                  src:"https://api.toshimon.io/Cards/"+c["Number"]+"/"+c["Number"],
                  name:c["Toshimon Name"],
                  id:c["Number"],
                  owned:cardBal,
                  index:c["Index"]
              })
              :
              cardBal > 0 &&
              ret.push({
                  src:"https://api.toshimon.io/Limited/DOGEMON.jpg",
                  name:"Doge",
                  id:[i+1],
                  owned:cardBal,
                  index:"152"
              })
          
      }
      setCardArray(ret);
     
    }
  }, [
    toshimonBalance,
    toshimonBalanceMatic
    

  ]);

  const getCardID = (i:number) => {
    const cardArr = ToshiJSON;
    let c:any = {}
    for(let ca in cardArr){
      if(cardArr[ca].Number == i){
        c = cardArr[ca];
        break;
      }
    }
    return c["Index"];
  }
  

  const onCardSelected = (event: any) =>{
    let src = event.currentTarget?.getAttribute("src")
    if(src){

      let id = parseInt(src.substr(src.lastIndexOf("/")+1,src.indexOf("-") - src.lastIndexOf("/") - 1)) - 1;
      let card = cardArray.find((card: any) => card.id == id + 1)
      setCardId(id+1);
      setDemCount(card.owned)
    }
   
    event.currentTarget.className == "dialog" ?
    
    setCardExpanded(null)
    :
    setCardExpanded(event.currentTarget.src);
  }

  

  
  return (
    <div >

      {[314].map((e, i) =>  {
         let card = cardArray.find((card: any) => card.id == 314)
         return cardArray && card && card ? 
         
           
              <li className="item">
                <Hover className="">
             <img
               onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
               onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
               style={{cursor:"pointer",borderRadius: "5%/4%"}}
               src="https://api.toshimon.io/Limited/DOGEMON.jpg"
               onClick={e => setDogeExpanded(true)}
               alt={card.name}
               
               
             />
 
             <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+card.owned}</div>
             </Hover>
             </li>
             
          
         
         :

          
           <li className="item" 

           >
           
        
         <img
           
           style={{borderRadius: "5%/4%",display:  "block" }}
             
           
               src=  "https://api.toshimon.io/Limited/DOGEMON.jpg"
              
               alt="Toshimon Card Back"
 
             />
            
             <div >
   
               <a target="_blank" style={{display:"block" }} href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/314"}><button className="hmeMXj">Buy on Opensea</button></a>
             </div>
           </li>
      })}
            {[315].map((e, i) =>  {
         let card = cardArray.find((card: any) => card.id == 315)
         return cardArray && card && card ? 
         
           
              <li className="item">
                <Hover className="">
             <img
               onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
               onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
               style={{cursor:"pointer",borderRadius: "5%/4%"}}
               src="https://api.toshimon.io/Limited/Majin.jpg"
               alt={card.name}
               
               
             />
 
             <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+card.owned}</div>
             </Hover>
             </li>
             
          
         
         :

          
           <li className="item" 
             
           >
           
         
         <img
           
           style={{borderRadius: "5%/4%",display:  "block" }}
             
           
               src=  "https://api.toshimon.io/Limited/Majin.jpg"
              
               alt="Toshimon Card Back"
 
             />
            
             <div >
             
               <a target="_blank"  href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/315"}><button className="hmeMXj">Buy on Opensea</button></a>
             </div>
           </li>
      })}
            {[316].map((e, i) =>  {
         let card = cardArray.find((card: any) => card.id == 316)
         return cardArray && card && card ? 
         
           
              <li className="item">
                <Hover className="">
             <img
               onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
               onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
               style={{cursor:"pointer",borderRadius: "5%/4%"}}
               src="https://api.toshimon.io/Limited/babyBera.jpg"
               alt={card.name}
               
               
             />
 
             <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+card.owned}</div>
             </Hover>
             </li>
             
          
         
         :

          
           <li className="item" 
              
           >
           
         <img
           
           style={{borderRadius: "5%/4%",display:  "block"}}
             
           
               src=  "https://api.toshimon.io/Limited/babyBera.jpg"
              
               alt="Toshimon Card Back"
 
             />
            
             <div >
          
               <a target="_blank"  href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/316"}><button className="hmeMXj">Buy on Opensea</button></a>
             </div>
           </li>
      })}

{[317].map((e, i) =>  {
         let card = cardArray.find((card: any) => card.id == 317)
         return cardArray && card && card ? 
         
           
              <li className="item">
                <Hover className="">
             <img
               onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
               onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
               style={{cursor:"pointer",borderRadius: "5%/4%"}}
               src="https://api.toshimon.io/Limited/Maren.png"
               alt={card.name}
               
               
             />
 
             <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+card.owned}</div>
             </Hover>
             </li>
             
          
         
         :

          
           <li className="item" 
              
           >
           
         <img
           
           style={{borderRadius: "5%/4%",display:  "block"}}
             
           
               src=  "https://api.toshimon.io/Limited/Maren.png"
              
               alt="Toshimon Card Back"
 
             />
            
             <div >
          
               <a target="_blank"  href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/317"}><button className="hmeMXj">Buy on Opensea</button></a>
             </div>
           </li>
      })}
{[318].map((e, i) =>  {
         let card = cardArray.find((card: any) => card.id == 318)
         return cardArray && card && card ? 
         
           
              <li className="item">
                <Hover className="">
             <img
               onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
               onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
               style={{cursor:"pointer",borderRadius: "5%/4%"}}
               src="https://api.toshimon.io/Limited/Neram.png"
               alt={card.name}
               
               
             />
 
             <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+card.owned}</div>
             </Hover>
             </li>
             
          
         
         :

          
           <li className="item" 
              
           >
           
         <img
           
           style={{borderRadius: "5%/4%",display:  "block"}}
             
           
               src=  "https://api.toshimon.io/Limited/Neram.png"
              
               alt="Toshimon Card Back"
 
             />
            
             <div >
          
               <a target="_blank"  href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/318"}><button className="hmeMXj">Buy on Opensea</button></a>
             </div>
           </li>
      })}
{[319].map((e, i) =>  {
         let card = cardArray.find((card: any) => card.id == 319)
         return cardArray && card && card ? 
         
           
              <li className="item">
                <Hover className="">
             <img
               onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
               onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
               style={{cursor:"pointer",borderRadius: "5%/4%"}}
               src="https://api.toshimon.io/Limited/Cobber.png"
               alt={card.name}
               
               
             />
 
             <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+card.owned}</div>
             </Hover>
             </li>
             
          
         
         :

          
           <li className="item" 
              
           >
           
         <img
           
           style={{borderRadius: "5%/4%",display:  "block"}}
             
           
               src=  "https://api.toshimon.io/Limited/Cobber.png"
              
               alt="Toshimon Card Back"
 
             />
            
             <div >
          
               <a target="_blank"  href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/319"}><button className="hmeMXj">Buy on Opensea</button></a>
             </div>
           </li>
      })}
{[320].map((e, i) =>  {
         let card = cardArray.find((card: any) => card.id == 320)
         return cardArray && card && card ? 
         
           
              <li className="item">
                <Hover className="">
             <img
               onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
               onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
               style={{cursor:"pointer",borderRadius: "5%/4%"}}
               src="https://api.toshimon.io/Limited/Lianti.png"
               alt={card.name}
               
               
             />
 
             <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+card.owned}</div>
             </Hover>
             </li>
             
          
         
         :

          
           <li className="item" 
              
           >
           
         <img
           
           style={{borderRadius: "5%/4%",display:  "block"}}
             
           
               src=  "https://api.toshimon.io/Limited/Lianti.png"
              
               alt="Toshimon Card Back"
 
             />
            
             <div >
          
               <a target="_blank"  href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/320"}><button className="hmeMXj">Buy on Opensea</button></a>
             </div>
           </li>
      })}


{[9999].map((e, i) =>  {
         return polymonBalance > 0 ? 
         
           
              <li className="item">
                <Hover className="">
             <img
               onPointerEnter={(e) => {e.currentTarget.style.boxShadow = "0 0 10px #B63BBF"}}
               onPointerLeave={(e) => {e.currentTarget.style.boxShadow = "none"}}
               style={{cursor:"pointer",borderRadius: "5%/4%"}}
               src="https://api.toshimon.io/Limited/Polymon.png"
               alt={"Polymon"}
               
               
             />
 
             <div className= "font-semibold" style={{textAlign:"center",width:"100%"}}>{"Owned: "+ polymonBalance}</div>
             </Hover>
             </li>
             
          
         
         :

          
           <li className="item" 
              
           >
           
         <img
           
           style={{borderRadius: "5%/4%",display:  "block"}}
             
           
               src=  "https://api.toshimon.io/Limited/Polymon.png"
              
               alt="Toshimon Card Back"
 
             />
            
             <div >
          
               <a target="_blank"  href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/9999"}><button className="hmeMXj">Buy on Opensea</button></a>
             </div>
           </li>
      })}
     
      
      {cardExpanded && (
        <dialog
          className="dialog flex flex-col w-screen min-h-screen items-center"
          style={{ position: 'fixed', minHeight: "100vh", top: '0', minWidth:"100%", backgroundColor:"rgba(0,0,0,.7)" }}
          open
          onClick={onCardSelected}
        >
          <div onClick={onCardSelected}>
            <div style={{position:"fixed",top:15,right:150}}>
              <button className="hmeMXj">
              
                {"Owned: "+ demCount}
              </button>
            </div>
            <div style={{position:"fixed",top:15,right:280}}>
            <a target="_blank" href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/"+cardID}>
            <button className="hmeMXj">
                
               {"View on Opensea"}
               
              
              </button>
            </a>
            </div>
            <div style={{textAlign:"center"}}>
            <img
              style={{boxShadow: "0 0 20px #B63BBF",width:"auto",borderRadius: "5%/4%", maxWidth: "90vw" , maxHeight: "80vh", marginTop: "10vh", marginLeft:"auto", marginRight:"auto"}}
              src={cardExpanded}
              alt={cardExpanded}
            />
            </div>
            
            
          </div>
          
        </dialog>
      )}
       {dogeExpanded && (
        <dialog
          className="dialog flex flex-col w-screen min-h-screen items-center"
          style={{ position: 'fixed', minHeight: "100vh", top: '0', minWidth:"100%", backgroundColor:"rgba(0,0,0,.7)" }}
          open
          onClick={e => setDogeExpanded(false)}
        >
          <div onClick={e => setDogeExpanded(true)}>
            <div style={{position:"fixed",top:15,right:150}}>
              <button className="hmeMXj">
              
                {"Owned: "+ cardArray && cardArray.find((card: any) => card.id == 314) ? cardArray.find((card: any) => card.id == 314).owned : 0}
              </button>
            </div>
            <div style={{position:"fixed",top:15,right:280}}>
            <a target="_blank" href ={"https://opensea.io/assets/0xd2d2a84f0eB587F70E181A0C4B252c2c053f80cB/314"}>
            <button className="hmeMXj">
                
               {"View on Opensea"}
               
              
              </button>
            </a>
            </div>
            <div style={{textAlign:"center"}}>
            <img
              style={{boxShadow: "0 0 20px #B63BBF",width:"auto",borderRadius: "5%/4%", maxWidth: "90vw" , maxHeight: "80vh", marginTop: "10vh", marginLeft:"auto", marginRight:"auto"}}
              src= "https://api.toshimon.io/Limited/DOGEMON.jpg"
              alt= "https://api.toshimon.io/Limited/DOGEMON.jpg"
            />
            </div>
            
            
          </div>
          
        </dialog>
      )}
 
      <Modal
        isOpen={modalIsOpen}
        onAfterClose={afterCloseModal}
        onRequestClose={closeModal}
        style={{
          content: {
            maxWidth: '720px',
            margin: 'auto'
          }
        }}
      >
        {selectedCardData && !isFetchingCardData ? (
          <article>
            <CardModalHeader>
              <CardModalTitle>{selectedCardData.name}</CardModalTitle>
              <div>#{getCardAttr(selectedCardData, 'Number')}</div>
            </CardModalHeader>

            <div className="clearfix">
              <div className="col-sm-6 text-center">
                <CardModalImg src={selectedCardData.image} alt={`${selectedCardData.name} card`} width="320" />
                {/* <CardModalStatTable className="clearfix text-center">
                  {['HP', 'Speed', 'Attack', 'Sp. Atk', 'Defense', 'Sp. Def'].map(stat => (
                    <div className="col-xs-4 col-sm-6" style={{marginBottom: '1rem'}}>
                      <dt>
                        {stat}
                      </dt>
                      <dd style={{color: '#303030'}}>
                        {getCardAttr(selectedCardData, stat)}
                      </dd>
                    </div>
                  ))}
                </CardModalStatTable> */}
              </div>

              <div className="col-sm-6">
                <CardModalDesc>{selectedCardData.description}</CardModalDesc>
              </div>
            </div>
          </article>
        ) : (
          <div className="text-center" style={{padding: '1rem'}}>Loading...</div>
        )}
      </Modal>
    </div>
  );
};

export default Cards;